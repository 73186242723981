import { Secure, Wallet, Convenient } from "../../../../../assets/images/index";

export const Animation = [
  {
    lottie: Convenient,
    name: "Convenient",
    background: "#DDDCF5",
  },
  {
    lottie: Wallet,
    name: "Budget-Friendly",
    background: "#FFE8CC",
  },
  {
    lottie: Secure,
    name: "Secure",
    background: "#E8E6FE",
  },
];

import React from 'react'
import {MdKeyboardDoubleArrowRight} from 'react-icons/md'
import './arrow-pointer.scss'

export const ArrowPointer = () => {
  return (
    <div className="text-end fs-1 arrow-container"> 
        <MdKeyboardDoubleArrowRight className=" arrow-icon"/>
    </div>
  )
}

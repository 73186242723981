import React, { useState } from "react";
import "./pamo-zone.scss";
import { ZoneBannerVideo } from "../../../assets/video";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Modal,
  Ratio,
  Row,
} from "react-bootstrap";
import { IoIosArrowDown } from "react-icons/io";
import { AiOutlinePlayCircle } from "react-icons/ai";
import { BiHomeAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import { BsPhone } from "react-icons/bs";

export const ZoneBanner = () => {
  const [show, setShow] = useState(false);

  const openVideoModal = () => {
    setShow(true);
  };

  return (
    <section>
      <div className=" pamo-zone-nav position-relative">
        <video autoPlay loop muted className="video-container">
          <source src={ZoneBannerVideo} type="video/mp4" />
        </video>

        <Row className="menu-link-row align-items-center mt-5">
          <Col md={6} lg={6} sm={6} xs={12}>
            <div
              className=" rounded-circle align-items-center d-flex "
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="500"
              data-aos-once="false"
              // data-aos-anchor-placement="top-center"
            >
              <Link to="/home">
                <Button
                  variant="primary"
                  className="rounded-circle home-btn p-3"
                >
                  <BiHomeAlt className="fs-1 text-white home-icon" />
                </Button>

                <Button
                  variant="tranparent"
                  className=" fs-5 fw-bold border-0 pamo-zone-btn mx-3 text-white"
                >
                  Pamo Zone
                </Button>
              </Link>
            </div>
          </Col>
          <Col>
            <div
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="500"
              data-aos-once="false"
              className="d-flex justify-content-end btn-talk-to-us"
            >
              <Dropdown>
                <Dropdown.Toggle
                  variant="primary"
                  id="talk-to-us-dropdown"
                  className="d-flex bg-primary py-2 px-3 rounded-5 align-items-center mt-4"
                >
                  <div className="bg-secondary rounded-circle p-2">
                    <BsPhone className="text-white phone-icon" />
                  </div>
                  <div className="mx-2 text-white">Talk to us</div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu border-0">
                  <div className="mx-4 my-3 border-bottom text-dark">
                    {" "}
                    Contact: <p className="text-primary  fw-bold">0599203040</p>
                  </div>

                  <Link
                    className="mx-4 my-3 text-secondary"
                    to="https://wa.me/message/BAD7RIBA5FHLA1"
                  >
                    WhatsApp
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Col>
        </Row>
        <Row className="menu-btn-row align-items-center">
          <Col>
            <div>
              <Button
                variant="info"
                className=" py-3 text-white px-3 d-flex watch-vid-btn align-items-center"
                onClick={() => openVideoModal()}
              >
                <AiOutlinePlayCircle className=" fs-1 play-icon" />
                <div className="fs-5 fw-bold mx-3 btn-text">Start watching</div>
              </Button>
            </div>
          </Col>

          <Col>
            <div
              className="d-flex justify-content-end"
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="500"
              data-aos-once="false"
            >
              <Button className="p-4 rounded-circle">
                <IoIosArrowDown className="text-white fw-bolder fs-2" />
              </Button>
            </div>
          </Col>
        </Row>
      </div>

      <Modal
        show={show}
        fullscreen={true}
        onHide={() => setShow(false)}
        className="video-modal-show"
      >
        <Modal.Header
          closeButton
          className="border-0 text-white"
        ></Modal.Header>
        <Modal.Body className="bg-white">
          <div className="bg-white">
            <Ratio aspectRatio="16x9">
              <video autoPlay loop className="video-container">
                <source src={ZoneBannerVideo} type="video/mp4" />
              </video>
            </Ratio>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
};

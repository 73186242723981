
import { Container } from "react-bootstrap";
import { PrivacyData } from "./privacy-data";
import './privacy.scss';

export const Privacy = () => {
  return (
    <section>
      <Container className="px-3">
        <div className="privacy-content mt-5">
          <p className="fs-2 fw-bold  privacy-title header-font">Privacy Policy for PAMO</p>
          <p className="privacy-description fs-6 ">
            Pamo ("us", "we", or "our") operates an e-commerce platform which
            connects buyers to sellers in different locations (the "Platform").
            We acknowledge your right to privacy of your personal information.
            This Privacy Policy (this “Policy”) informs you of our policies
            regarding the collection, use and disclosure of personal information
            we receive from users and visitors of the Platform. We use your
            personal information only for providing our services and improving
            the Platform. By using the Platform, you agree to the collection and
            use of information in accordance with this Policy or the latest
            revised version of this Policy.
          </p>
        </div>

        <div className="mb-5">
          {PrivacyData.map((data, key) => (
            <ul key={key}>
              <div className="fs-5 fw-bold mt-5 privacy-items header-font">{data.listName}</div>
              {data.List.map((data,key)=>(
                <li className="my-2 privacy-items">{data.item}</li>
              ))}
            </ul>
          ))}
        </div>
      </Container>
    </section>
  );
};

import React from "react";
import { Button, Container } from "react-bootstrap";
import "./what-is-pamo.scss";
import { HashLink as Link } from "react-router-hash-link";

export const WhatIsPamo = () => {
  return (
    <div id="what-is-pamo-container">
      {/* <Container
        className="text-center what-is-pamo-main-container"
        id="what-is-pamo-container"
      >
        <div
          className="what-is-pamo-header fw-bold mb-5"
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="600"
          data-aos-once="false"
          data-aos-anchor-placement="top-center"
        >
          What is Pamo ?
        </div>
        <div
          className="what-is-pamo-body fw-bold"
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="500"
          data-aos-once="false"
          data-aos-anchor-placement="top-center"
        >
          <div>
            Pamo is a shopping app which allows buyers to post items they want
            to buy at the prices they are willing to pay for sellers to make
            them offers.
          </div>
          <div className="what-is-pamo-btn">
            <Link to="https://bit.ly/PamoApp">
              <Button
                variant="primary"
                className="text-white p-3 fw-bold rounded-5 mt-4"
              >
                Download for Andriod or IOS
              </Button>
            </Link>
            <div className="what-is-pamo-link mt-2">
              <Link
                to="https://bit.ly/PamoApp"
                className="text-primary fs-6 fw-bold text-decoration-underline"
              >
                Click to see how Pamo works
              </Link>
            </div>
          </div>
        </div>
      </Container> */}
      <div className="container text-center my-lg-5 py-lg-6 py-4">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-8 col-md-10 mb-4 d-flex justify-content-center flex-wrap"></div>
          <div className="col-xl-7 col-lg-8 col-md-10">
            <div className="lc-block mb-4">
              <div className="header-font">
                <h2 className="rfs-25 fw-bolder fs-1"> What is Pamo ?</h2>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-9 col-sm-10">
            <div className="">
              <p className="lead fs-2 pamo-mission">
                Pamo is a shopping app which allows buyers to post items they
                want to buy at the prices they are willing to pay for sellers to
                make them offers.
              </p>
            </div>
          </div>
          <div className="what-is-pamo-btn">
            <Link to="https://bit.ly/PamoApp">
              <Button
                variant="primary"
                className="text-white p-3 fw-bold rounded-5 mt-4 button-outline"
              >
                Download for Andriod or IOS
              </Button>
            </Link>
            <div className="what-is-pamo-link mt-2">
              <Link
                to="https://bit.ly/PamoApp"
                className="text-primary fs-6 fw-bold text-decoration-underline"
              >
                Click to see how Pamo works
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { Button, Card, Modal, Ratio } from "react-bootstrap";
import { AiOutlinePlayCircle } from "react-icons/ai";
import { Mousewheel } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/pagination";
import ReactPlayer from "react-player";
import "video-react/dist/video-react.css";
import { fetchApi } from "../../../service/services";
import ContentShimmer from "react-content-shimmer";
import { Autoplay, Pagination } from "swiper";
import { isMobile } from "react-device-detect";
import "../../../index.scss";

interface VideoLink {
  id: number;
  acf: {
    zone_videos: string;
  };
  title: {
    rendered: string;
  };
}

const VideoCard = ({ videoUrl, videoTitle, onClick }: any) => {
  return (
    <div
      className="mx-3 youtube-videos"
      data-aos="fade-up "
      data-aos-easing="linear"
      data-aos-duration="500"
      data-aos-once="false"
      data-aos-anchor-placement="down-center"
    >
      <Card className="video-carousel rounded-5 position-relative mt-5">
        <Button
          variant="transparent"
          className="position-absolute translate-middle start-50 top-50"
          onClick={() => onClick(videoUrl)}
        >
          <AiOutlinePlayCircle className=" play-icon text-white" />
        </Button>
      </Card>
      <div
        className="mx-3 mt-2 carousel-text fw-bold "
        style={{ width: "300px" , fontSize: "18px"}}
      >
        {videoTitle}
      </div>
    </div>
  );
};

const ZoneVideo = () => {
  const [videoSrc, setVideoSrc] = useState<VideoLink[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchApi("pamo_zone_videos")
      .then((data) => {
        setVideoSrc(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, []);

  const [showVideoModal, setShowVideoModal] = useState(false);
  const [link, setLink] = useState("");
  const [show, setShow] = useState(false);

  const openVideoModal = (videoUrl: string) => {
    setLink(videoUrl);
    setShowVideoModal(true);
    setShow(true);
    console.log(videoUrl);
  };

  const openMobileVideo = (videoUrl: string) => {
    setLink(videoUrl);
    setShowVideoModal(true);
    setShow(true);
    console.log(videoUrl);
  };

  const shimmer = Array.from({ length: 5 }, (item, index) => (
    <div key={index}>
      <ContentShimmer
        style={{ width: "300px", height: "200px", marginRight: "15px" }}
        rounded={"20px"}
      />
      <ContentShimmer
        style={{
          width: "300px",
          height: "15px",
          marginRight: "15px",
          marginTop: "10px",
        }}
        rounded={"20px"}
      />
    </div>
  ));

  return (
    <section
      className="zone-header"
      style={{ marginTop: "150px", marginBottom: "150px" }}
    >
      <div className="fw-bold mt-3 text-black video-header header-font">
        <h2 className="rfs-25 fw-bolder fs-1 text-left">Pamo Videos</h2>
      </div>
      {isLoading ? (
        <div className="d-flex shimmer overflow-auto mt-5">{shimmer}</div>
      ) : (
        <>
          <div className="d-flex videos-card-container">
            {videoSrc.map((data, key) => (
              <VideoCard
                key={key}
                videoUrl={data?.acf?.zone_videos}
                videoTitle={data?.title?.rendered}
                onClick={() => openVideoModal(data?.acf?.zone_videos)}
              />
            ))}
          </div>
          <div className="for-mobile-view">
            <Swiper
              slidesPerView={1}
              spaceBetween={20}
              pagination={{
                clickable: true,
                el: ".custom-pagination",
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Mousewheel, Pagination, Autoplay]}
              direction={"horizontal"}
              mousewheel={{
                forceToAxis: true,
              }}
              grabCursor={true}
              className="mySwiper"
            >
              {videoSrc.map((data, key) => (
                <SwiperSlide key={key}>
                  <VideoCard
                    videoUrl={data?.acf?.zone_videos}
                    videoTitle={data?.title?.rendered}
                    onClick={() =>
                      isMobile
                        ? openVideoModal(data?.acf?.zone_videos)
                        : openMobileVideo(data?.acf?.zone_videos)
                    }
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="custom-pagination"></div>
          </div>
        </>
      )}
      <Modal
        show={show}
        fullscreen={isMobile ? true : undefined}
        onHide={() => setShow(false)}
        className="video-modal-show"
      >
        <Modal.Header
          closeButton
          className="border-0 text-white"
        ></Modal.Header>
        <Modal.Body
          style={{
           height:"100vh",
            overflowY: "auto",
          }}
        >
          <div>
            <Ratio aspectRatio="16x9" className="ratio-size">
              <ReactPlayer
                url={link}
                width="100%"
                height="100vh"
                playing={show}
                controls={true}
                loop={false}
                muted={false}
                config={{
                  youtube: {
                    playerVars: {
                      autoplay: 1,
                    },
                  },
                }}
              />
            </Ratio>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default ZoneVideo;

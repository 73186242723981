import { Container, Row, Col } from "react-bootstrap";
import { QuickStepData } from "./pamo-quick-data";
import { HoverCard } from "../hover-cards/HoverCards";
import "./pamo-quick-step.scss";
import { ArrowPointer } from "../../../common/navigating-arrow/ArrowPointer";

export const PamoQuickStep = () => {
  return (
    <Container
      fluid
      className="tutorial-section"
      id="pamo-quick-steps"
      style={{ marginTop: "200px", marginBottom: "150px" }}
    >
      <div className="tutorial-parent-header text-center my-5">
        {/* <p className="quick-steps-header fw-bold text-center my-5">
          Here’s How! Connect to People Already Looking to Buy: Sell with Pamo
          Today!
        </p> */}
        <div className="header-font">
          <h2 className="rfs-25 fw-bolder fs-1">Here’s How! Connect to People Already Looking to Buy: Sell with Pamo
          Today!</h2>
        </div>{" "}
      </div>
      <div className="tut-card-container d-flex jusfify-content-center">
        <div className="tutorial-card-container d-flex flex-nowrap overflow-auto  jusfify-content-start justify-content-lg-center align-items-stretch ">
          {QuickStepData.map((data, key) => (
            <div key={key} className="tutorial-card-col mx-3 my-5">
              <div className="equal-height-card-wrapper">
                <HoverCard data={data} />
              </div>
            </div>
          ))}
        </div>
      </div>
      <ArrowPointer />
    </Container>
  );
};

import { Container } from "react-bootstrap";

import "./ts-cs.scss";
import { TermsConditionsData } from "./ts-cs-data";

export const TermsAndConditions = () => {
  return (
    <section>
      <Container className="px-3">
        <div className="ts-cs-content mt-5">
          <p className="fs-2 fw-bold  privacy-title header-font">Introduction</p>
          <p className="ts-cs-description fs-6 ">
            These Terms and Conditions (‘Terms’) and all policies and additional
            terms posted on and in our sites, applications, tools and services
            (collectively "Services") set out the terms on which Pamo offers you
            access to and use of our Services. These Terms are applicable to and
            enforceable against anyone who accesses our services through this
            website or our applications.
          </p>
        </div>

        <div className="mb-5">
          {TermsConditionsData.map((data, key) => (
            <ul key={key}>
              <div className="fs-5 fw-bold mt-5 ts-cs-items header-font">
                {data.listName}
              </div>
              {data.list.map((data, key) => (
                <li className="my-2 ts-cs-items">{data.item}</li>
              ))}
            </ul>
          ))}
        </div>
      </Container>
    </section>
  );
};

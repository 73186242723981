import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Card, Image } from "react-bootstrap";
import { IoIosArrowDown } from "react-icons/io";
import { fetchApi } from "../../../../../service/services";
import ContentShimmer from "react-content-shimmer";
import { HashLink as Link } from "react-router-hash-link";
import HomeBG from "../../../../../assets/images/banner/lady-one.png";
import "./BuyOnPamobanner.scss";

interface BannerItem {
  id: number;
  acf: {
    flyer_upload: string;
  };
}

export const BuyOnPamobanner = () => {
  const [bannerFlyer, setBannerFlyer] = useState<BannerItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchApi("buy_on_pamo")
      .then((data) => {
        setBannerFlyer(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <Container>
        <ContentShimmer
          style={{ marginTop: "1rem", width: "w-100", height: "500px" }}
          rounded={"10px"}
        />
      </Container>
    );
  } else if (!isLoading && Object.keys(bannerFlyer).length === 0) {
    return (
      <div className="container-fluid BuyOn_banner-section">
        <div className="mt-lg-5">
          <div className="banner-bgg">
            <Row className="flex-lg-row-reverse flex-column-reverse align-items-center pt-5">
              <div className="d-flex justify-content-start d-lg-block col-lg-4 mx-auto mt-5 mt-lg-0">
                {/* <img src="https://images.unsplash.com/photo-1530435460869-d13625c69bbf?crop=entropy&amp;cs=tinysrgb&amp;fit=crop&amp;fm=jpg&amp;ixid=MnwzNzg0fDB8MXxzZWFyY2h8MTB8fHdlYnNpdGV8ZW58MHwwfHx8MTYyMTQ0NjkyNg&amp;ixlib=rb-1.2.1&amp;q=80&amp;w=1080&amp;h=768" className="d-block mx-lg-auto img-fluid" alt="" loading="lazy"/> */}
                <div className="ms-3">
                  <Card className="buy-card-hovery m-2 p-3 ">
                    <Card.Body className="position-relative">
                      <div className="rounded-circle text-end " style={{marginTop: "-27px", marginRight: "-18px"}}>
                        <div className="rounded-circle bg-info spot d-inline-block p-2 text-end"></div>
                      </div>
                      <div className="buy-alternating-images"></div>
                    </Card.Body>
                    <Card.Footer className="buy-post-made text-center">
                      Post made!
                    </Card.Footer>
                  </Card>
                </div>
              </div>
              <div className="col-lg-7 col-xl-6 px-4 pt-lg-5 d-flex flex-column justify-content-center">
                <div className="mb-3 pt-lg-1 text-header">
                  <div>
                    <h2 className="text-white fw-light text-center text-lg-start display-4 header-font">
                      {" "}
                      <span className="fw-bold text-break">
                        Buyers post
                      </span>{" "}
                      what they want to buy,{" "}
                      <span className="fw-bold text-break">sellers reply.</span>
                    </h2>
                  </div>
                </div>

                <div className="mb-3 font">
                  <div>
                    <p className="lead fs-4 text-center text-lg-start text-white ">
                      That's the Pamo way!
                    </p>
                  </div>
                </div>

                <div className="d-grid d-md-flex flex-lg-column flex-xl-row align-items-center align-items-lg-start align-items-xl-center justify-content-center justify-content-lg-start">
                  <Link to="https://bit.ly/PamoApp">
                    <Button
                      variant="info"
                      className=" fw-bold py-4 px-4 text-white  download-btn"
                    >
                      <span className="download-btn-text font">
                        Download for Android or IOS
                      </span>
                    </Button>{" "}
                  </Link>
                  <div className="scroll-down-to-section my-3 text-center text-lg-start reward">
                    <Link
                      smooth
                      to="#reward-main-section"
                      className="text-white fw-bold text-link  text-decoration-underline mx-3"
                    >
                      {" "}
                      What is Pamo?
                    </Link>
                  </div>
                </div>
              </div>
            </Row>
            <div className="p-3 text-end position-relative">
              <Link smooth to="#reward-main-section">
                <Button className="p-4 rounded-circle btn-glass border-0">
                  <IoIosArrowDown className="text-white fw-bolder fs-2" />
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <Container id="promo-section">
        {bannerFlyer.map((item, key) => (
          <div className="promo-banner" key={key}>
            <Image
              className="flyer"
              src={item?.acf?.flyer_upload}
              key={item?.id}
            />
          </div>
        ))}
      </Container>
    );
  }
};


import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import {
  Gifts,
  Delivery,
  Vip,
  Cashback,
  Coupons,
  Chat,
} from "../../../../../assets/images/index";
import Lottie from "react-lottie-player";
import "./rewards.scss";

export const Rewards = () => {
  const Data = [
    { image: Coupons, title: "Discount Coupons" },
    { image: Gifts, title: "Gifts" },
    { image: Delivery, title: "Free Delivery" },
    { image: Cashback, title: "Cashback" },
    { image: Vip, title: "Exclusive Access" },
  ];
  return (
    <section className="reward-session">
      <div
        className="open-chat-section wrapper"
        data-aos="fade-up"
        data-aos-easing="linear"
        data-aos-duration="500"
        data-aos-once="false"
        // data-aos-anchor-placement="top-center"
      >
        <Row
          className="rewards-container"
          style={{ marginTop: "200px", marginBottom: "250px" }}
        >
          <div className="chat-lottie-animation d-flex justify-content-center ">
            <Lottie
              loop
              animationData={Chat}
              play
              // style={{ width: "400px", height: "100%" }}
            />
          </div>
          <div className="fw-bold text-center make-offers-title mb-5">
            <div className="header-font">
              <h2 className="rfs-25 fw-bolder fs-1">Open Chat</h2>
            </div>{" "}
          </div>

          {/* <Row
          className="border-bottom rows row-md-2"
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="500"
          data-aos-once="false"
          // data-aos-anchor-placement="top-center"
        >
          <Col
            className="border-end p-4 fs-5 mx-auto custom-col"
            lg={3}
            md={12}
            sm={12}
          >
            <p className="fs-4 fw-bold">Offer-o-Rama :</p>
            <span className="text-muted fs-5">
              Vendors flood you with fantastic offers! It's like a treasure hunt
              and you are the treasure!
            </span>
          </Col>
          <Col
            className="border-end p-4 fs-5 mx-auto custom-col"
            lg={3}
            xs={12}
          >
            <p className="fs-4 fw-bold">Chatty Connections :</p>
            <span className="text-muted fs-5">
              Have questions about the product? Connect with vendors with the
              Open Chat Experience to have all your answers!{" "}
            </span>
          </Col>
          <Col className="p-4  mx-auto custom-col" lg={3} xs={12}>
            <p className="fs-4 fw-bold">Bargain Bliss : </p>
            <span className="text-muted fs-5">
              Unleash your inner haggler! Negotiate like a pro and snag deals
              that'll have you saying, 'Oh yeah!
            </span>
          </Col>
        </Row>
        <Row>
          <Col
            className="border-end p-4 fs-4 mx-auto custom-col"
            lg={3}
            md={12}
          >
            <p className="fw-bold">Deal Sealer :</p>
            <span className="text-muted fs-5">
              Once you find a vendor with what you want and agree on a price,
              seal the deal with a satisfying tap – "Accept Offer"!
            </span>
          </Col>
          <Col
            className="border-end p-4 fs-4 mx-auto custom-col"
            lg={3}
            xs={12}
          >
            <p className="fw-bold">Delivery Magic :</p>
            <span className="text-muted fs-5">
              With the deal sealed, Pamo riders pick up the product from the
              vendor and deliver it to the buyer’s doorstep!{" "}
            </span>
          </Col>
          <Col className="p-4 fs-4 mx-auto custom-col" lg={3} xs={12}>
            <p className="fw-bold"> Confirmation Celebration :</p>
            <span className="text-muted fs-5">
              Give that thumbs-up to confirm you've received the item, and
              voilà! The Vendor’s tradecard is credited!
            </span>
          </Col>
        </Row> */}
          <div
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="500"
            data-aos-once="false"
          >
            <section className="py-8">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-6 col-lg-4 border-top-md border-bottom p-4 border-end">
                    <div className="py-7 text-center">
                      <div className="lh-sm">
                        <h2 className="mb-4 fs-4 fw-semibold">Offer-o-Rama</h2>
                        <span className="text-muted fs-5">
                          Vendors flood you with fantastic offers! It's like a
                          treasure hunt and you are the treasure!
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 border-top-md border-bottom p-4 border-end">
                    <div className="py-7 text-center">
                      <div className="lh-sm">
                        <h2 className="mb-4 fs-4 fw-semibold">
                          Chatty Connections
                        </h2>
                        <span className="text-muted fs-5">
                          {" "}
                          Have questions about the product? Connect with vendors
                          with the Open Chat Experience to have all your
                          answers!
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 border-top-lg border-bottom p-4">
                    <div className="py-7 text-center">
                      <div className="lh-sm">
                        <h2 className="mb-4 fs-4 fw-semibold">Bargain Bliss</h2>
                        <span className="text-muted fs-5">
                          {" "}
                          Unleash your inner haggler! Negotiate like a pro and
                          snag deals that'll have you saying, 'Oh yeah!
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="py-8">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-6 col-lg-4 border-top-md p-4 border-end">
                    <div className="py-7 text-center">
                      <div className="lh-sm">
                        <h2 className="mb-4 fs-4 fw-semibold">Deal Sealer </h2>
                        <span className="text-muted fs-5">
                          Once you find a vendor with what you want and agree on
                          a price, seal the deal with a satisfying tap – "Accept
                          Offer"!
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 border-top-md p-4 border-end">
                    <div className="py-7 text-center">
                      <div className="lh-sm">
                        <h2 className="mb-4 fs-4 fw-semibold">
                          Delivery Magic
                        </h2>
                        <span className="text-muted fs-5">
                          {" "}
                          With the deal sealed, Pamo riders pick up the product
                          from the vendor and deliver it to the buyer’s
                          doorstep!
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 border-top-lg p-4">
                    <div className="py-7 text-center">
                      <div className="lh-sm">
                        <h2 className="mb-4 fs-4 fw-semibold">
                          Confirmation Celebration
                        </h2>
                        <span className="text-muted fs-5">
                          {" "}
                          Give that thumbs-up to confirm you've received the
                          item, and voilà! The Vendor’s tradecard is credited!
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Row>
        <Row className="rewards-container my-5">
          <div className="rewards-header fw-bold text-center mt-5">
            <div className="header-font">
              <h2 className="rfs-25 fw-bolder fs-1">
                The rewards are endless!
              </h2>
            </div>
          </div>
          <p
            className="rewards-body text-center fw-light mt-3 fs-5"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="500"
            data-aos-once="false"
            // data-aos-anchor-placement="top-center"
          >
            Journey with Pamo, Earn points, Redeem as rewards!
          </p>
          <Col
          
            className="d-flex pb-5 flex-wrap overflow-auto rewards-slide"
            style={{ marginBottom: "150px" }}
          >
            {Data.map((item, key) => (
              <div className="reward-items mx-3" key={key}>
                <div
                  className="  rounded-circle  hover-reward-card"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration="700"
                  data-aos-once="false"
                >
                  <Image
                    src={item.image}
                    className="reward-slide-image"

                    // data-aos-anchor-placement="top-center"
                  />
                </div>

                <div
                  className="reward-slide-image-text text-black text-center mt-2 fw-light"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration="900"
                  data-aos-once="false"
                >
                  {item.title}
                </div>
              </div>
            ))}
          </Col>
        </Row>
      </div>
    </section>
  );
};

import React from "react";
import { Col, Container, Row, Card, Button } from "react-bootstrap";
import { Animation } from "./animation-data";
import { TradeCard } from "../../../../../assets/images";
import Lottie from "react-lottie-player";
import "./why-pamo.scss";
import { HashLink as Link } from "react-router-hash-link";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import "swiper/css/navigation";

export const WhyPamo = () => {
  return (
    <Container className="z-3 " id="why-pamo-container" style={{marginTop: "250px"}}>
      <div
        data-aos="fade-up"
        data-aos-easing="linear"
        data-aos-duration="500"
        data-aos-once="false"
      >
        <div className="header-font">
          <h2 className="text-center fw-bold fs-1">Why Pamo?</h2>
        </div>

        <div className="container col-sm-6 col-md-9 col-lg-11 col-xl-9 col-10 mt-5">
          <Swiper
            modules={[Navigation, Autoplay, Pagination]}
            loop={true}
            slidesPerView={3}
            // spaceBetween={5}
            autoplay={{
              delay: 10000,
              disableOnInteraction: false,
            }}
            pagination={{ clickable: true }}
            breakpoints={{
              0: {
                // spaceBetween: 10,
                slidesPerView: 1,
              },
              767: {
                spaceBetween: 20,
                slidesPerView: 2,
              },
              1024: {
                spaceBetween: 30,
                slidesPerView: 3,
              },
            }}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log("slide change")}
          >
            <div className="row">
              {Animation.map((data, key) => (
                <SwiperSlide>
                  <div className=" mx-auto">
                    <div className="card rounded-5 p-4 bg-white text-dark">
                      <div
                        className="rounded-5"
                        style={{ background: data.background }}
                      >
                        {" "}
                        <Lottie
                          loop
                          animationData={data.lottie}
                          play
                          style={{
                            margin: "auto",
                            width: "200px",
                            height: "270px",
                          }}
                          className="lottie-animation mx-auto"
                        />
                      </div>

                      <div className="why-pamo-btn d-inline-flex mt-2 ">
                        <div
                          className="why-pamo-text text-black fw-light py-3 rounded-5 px-3 w-75 text-center text-muted fs-5"
                          style={{ background: data.background }}
                        >
                          {data.name}
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        </div>
      </div>
      <Container>
        <Row className="align-items-center p-0 m-0 position-relative trade-card-section">
          <Col md={12} lg={7} className="lottie-col">
            <Lottie
              loop
              animationData={TradeCard}
              play
              style={{ width: "100%", height: "750px" }}
              className="trade-card"
            />
          </Col>
          <Col className="text-col text-center text-lg-start mb-5">
            <div
              className="trade-card-header fw-bold"
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="500"
              data-aos-once="false"
            >
              <div className="header-font">
                <h2 className="fw-bold fs-1 text-left">Trade Card</h2>
              </div>
            </div>
            <div
              className="trade-card-body text-center text-lg-start fw-light"
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="1000"
              data-aos-once="false"
            >
              Pamo's personalized virtual shopping card allows buyers to use
              money on their trade card for negotiations and payments, ensuring
              that funds are transferred only after accepting the item and being
              satisfied with the purchase. 💳🛍️
            </div>
            <Link to="https://bit.ly/PamoApp">
              <Button
                variant="primary"
                className="rounded-5 mt-5 text-white p-3 button-outline"
                data-aos="flip-down"
                data-aos-easing="linear"
                data-aos-duration="500"
                data-aos-once="false"
              >
                Download for Android 0r IOS
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export const PostData = [
  {
    id: "1",
    title: `Post your item on the Pamo App📷 `,
    background: "#DDDCF5",
    body: "Download Pamo app on Playstore or Appstore and sign up. Upload a photo of the item you want to buy with all relevant specs. If you don’t have a picture of the item don’t worry, you can simply type out a description.",
  },
  {
    id: "2",
    title: `Get to choose the best prices😌 `,
    background: "#FFE8CC",
    body: "Vendors with your product will reach out to you with their best prices. You can negotiate for the best price all in the app!",
  },
  {
    id: "3",
    title: `Seal the deal like a boss:⚡`,
    background: "#E8E6FE",
    body: "Satisfied with the price, top up your Pamo Trade Card and seal the deal. You can top up your Trade Card with mobile money or VISA card Now it's time to accept the offer and close the deal!",
  },
];

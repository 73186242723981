// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ts-cs-description {
  text-align: justify; }

.ts-cs-items {
  text-align: justify; }

.header-font {
  font-family: "Clash Display"; }
`, "",{"version":3,"sources":["webpack://./src/presentation/main/terms-and-condition/ts-cs.scss"],"names":[],"mappings":"AAAA;EACE,mBAAmB,EAAA;;AAGrB;EACE,mBAAmB,EAAA;;AAGrB;EACE,4BAA4B,EAAA","sourcesContent":[".ts-cs-description {\n  text-align: justify;\n}\n\n.ts-cs-items {\n  text-align: justify;\n}\n\n.header-font {\n  font-family: \"Clash Display\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

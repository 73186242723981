import React from "react";
import { Banner } from "./components/banner/BannerCard";
import { TrendProductCards } from "./components/trend-products/TrendProductCards";
import { WhatIsPamo } from "./components/what-is-pamo/WhatIsPamo";
import { WhyPamo } from "./components/why-pamo/WhyPamo";
import HomeBanner from "./components/home-banner/HomeBanner";


export const Home = () => {
  return (
    <React.Fragment>
     {/* <Banner/> */}
     <HomeBanner/>
     <TrendProductCards/>
     <WhatIsPamo/>
     <WhyPamo/>
    </React.Fragment>
  );
};

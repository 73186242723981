
import ContentShimmer from "react-content-shimmer";

export const ArticleShimmer = () => {
  return (
    <>
      <ContentShimmer
          style={{ width: "70%", height: "20px", marginRight: "15px" }}
          rounded={"20px"}
        />
        <ContentShimmer
        style={{ width: "80%", height: "20px", marginRight: "15px" , marginTop:"10px"}}
        rounded={"20px"}
      />

     <ContentShimmer
        style={{ width: "100%", height: "50vh", marginRight: "15px" , marginTop:"30px"}}
        rounded={"20px"}
      />

     <ContentShimmer
        style={{ width: "100%", height: "10px", marginRight: "15px" , marginTop:"30px"}}
        rounded={"20px"}
      />
      <ContentShimmer
        style={{ width: "100%", height: "10px", marginRight: "15px" , marginTop:"10px"}}
        rounded={"20px"}
      />
      <ContentShimmer
        style={{ width: "100%", height: "10px", marginRight: "15px" , marginTop:"10px"}}
        rounded={"20px"}
      />
      <ContentShimmer
        style={{ width: "100%", height: "10px", marginRight: "15px" , marginTop:"10px"}}
        rounded={"20px"}
      />

      <ContentShimmer
        style={{ width: "100%", height: "10px", marginRight: "15px" , marginTop:"10px", marginBottom:"10%"}}
        rounded={"20px"}
      />
     </>
  )
}

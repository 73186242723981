import { Container, Image } from "react-bootstrap";
import { LightLogo } from "../.../../../../../assets/images/index";
import "./footer.scss";
import { AiFillTwitterCircle, AiFillInstagram } from "react-icons/ai";
import { BsYoutube, BsFacebook } from "react-icons/bs";
import { Link } from "react-router-dom";
import { IoLogoWhatsapp } from "react-icons/io";

export const Footer = () => {
  return (
    <section className="bg-primary footer-main-container py-3 rounded-5">
      <div className="footer-parent-container align-items-center  d-flex flex-wrap justify-content-between">
        <div className="footer-logo-and-location d-flex align-items-center ">
          <div className="footer-logo text-center">
            <Link to="/home">
              <Image src={LightLogo} alt="logo" className="footer-logo-img " />
            </Link>
          </div>
          <div className="pamo-location text-white mx-5">
            <div className="text-break">
              3 Bearded Street, North Legon.{" "}
            </div>
            {/* <div className="text-break">North Legon.</div> */}
          </div>
        </div>
        <div className="footer-socials-icons d-inline-flex bg-primary p-3 rounded-5">
          <div className="twitter-icon mx-1">
            <a href="https://twitter.com/pamoapp">
              <AiFillTwitterCircle className="text-white fs-3" />
            </a>
          </div>
          <div className="youtube-icon  mx-1">
            <a href="https://www.youtube.com/@pamoapp">
              {" "}
              <BsYoutube className="text-primary fs-3 bg-white rounded-circle p-1" />
            </a>
          </div>
          <div className="instagram-icon  mx-1">
            <a href="https://www.instagram.com/pamo_app/">
              {" "}
              <AiFillInstagram className="text-primary fs-3 bg-white rounded-circle p-1" />
            </a>
          </div>
          <div className="facebook-  mx-1">
            <a href="https://www.facebook.com/pamoapp">
              {" "}
              <BsFacebook className="text-white fs-3" />
            </a>
          </div>
          <div className="whatsApp  mx-1">
            <a href="https://wa.me/message/BAD7RIBA5FHLA1">
              {" "}
              <IoLogoWhatsapp className="text-primary fs-3 bg-white rounded-circle p-1" />
            </a>
          </div>
        </div>
        <div className="footer-links d-flex">
          <div className="privacy-link  mx-3">
            <a href="/privacy" className="text-white text-decoration-underline">
              Privacy
            </a>
          </div>
          <div className="ts-and-cs-link mx-3">
            <a
              href="/terms-and-conditions"
              className=" text-white text-decoration-underline"
            >
              Terms & Conditions
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

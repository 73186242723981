import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchApi } from "../../../service/services";
import { ShimmerCard } from "./shimmer";
import ContentShimmer from "react-content-shimmer";
import { ArticleShimmer } from "./article-shimmer";
import "../../../index.scss"

interface BlogItem {
  id: number;
  title: {
    rendered: string;
  };
  content: {
    rendered: string;
  };
  excerpt: {
    rendered: string;
  };
  better_featured_image: {
    source_url: string;
  };
  acf: {
    date: string;
  };
}

export const BlogContent = () => {
  const { id } = useParams<{ id: string }>();
  const [article, setArticle] = useState<BlogItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchApi(`pamo_zone_blog/${id}`)
      .then((data) => {
        setArticle(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, []);

  const shimmer = Array.from({ length: 1 }, (item, index) => (
    <ArticleShimmer />
  ));



  return (
    <section className="blog-content-section">
      {isLoading ? (
        <div className="mt-5">{shimmer}</div>
      ) : (
        <div>
          
          {article.map((item: any, key: any) => (
            <div key={key}>
              <div>
                <div className="fw-bold fs-1 my-5 header-font">{item?.title?.rendered}</div>
              </div>

              <div>
                <img
                  src={item?.better_featured_image?.source_url}
                  className="rounded-5 "
                  style={{
                    width: "100%",
                    height: "50vh",
                    objectFit: "cover",
                    objectPosition: "top",
                  }}
                />
              </div>
              <div
                className="my-5 article-body"
                dangerouslySetInnerHTML={{
                  __html: item?.content?.rendered,
                }}
              />
               <div className="text-end fw-bold mb-5 fs-6">{item?.acf?.date}</div>
            </div>

           
          ))}
        </div>
      )}
    </section>
  );
};

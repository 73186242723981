import React from 'react'
import { Banner } from './component/banner/BannerCard'
import { PamoQuickStep } from './component/pamo-quick-step/PamoQuickStep'
import { Merchantslide } from './component/merchant-slide/Merchantslide'
import { Categories } from './component/categories/Categories'
import './sell-with-pamo.scss';
import { SellWithPamoBanner } from './component/SellWithPamoBanner/SellWithPamoBanner'

export const SellWithPamo = () => {
  return (
   <section className='section-main'>
    {/* <Banner/> */}
    <SellWithPamoBanner/>
    <PamoQuickStep/>
    {/* <Merchantslide/> */}
    <Categories/>
   </section>
  )
}

import {
  Computer,
  Mobile,
  Adult,
  Machines,
  Automobile,
  Groceries,
  Gift,
  Book,
  Fitness,
  Fashion,
  Cosmetic,
  Music,
  Security,
  Pet,
  Game,
  Tools,
  Construction,
  Office,
  Baby,
  Electronics,
} from "../../../../../assets/images/index";
export const CategoryData = [
  {
    title: "Phones & Tablets",
    icon:`${Mobile}`
  },
  {
    title: "Computers",
    icon:`${Computer}`
  },
  {
    title: "Consumer Electronics",
    icon:`${Electronics}`
  },
  {
    title: "Fashion",
    icon:`${Fashion}`
  },
  {
    title: "Beauty & Health",
    icon:`${Cosmetic}`
  },
  {
    title: "Groceries",
    icon:`${Groceries}`
  },
  
  {
    title: "Baby",
    icon:`${Baby}`
  },
  {
    title: "Adult",
    icon:`${Adult}`
  },
  {
    title: "Home & Office",
    icon:`${Office}`
  },
  {
    title: "Gifts & Cards",
    icon:`${Gift}`
  },
  {
    title: "Books & Stationery",
    icon:`${Book}`
  },
  {
    title: "Automobile",
    icon:`${Automobile}`
  },
  {
    title: "Tools & Hardware",
    icon:`${Tools}`
  },
  {
    title: "Security & Protection",
    icon:`${Security}`
  },
  {
    title: "Machinery & Industrial Parts",
    icon:`${Machines}`
  },
  {
    title: "Construction & Real Estate",
    icon:`${Construction}`
  },
  {
    title: "Pet & Pet Supplies",
    icon:`${Pet}`
  },
  {
    title: "Music Instruments",
    icon:`${Music}`
  },
  {
    title: "Sport & Fitness",
    icon:`${Fitness}`
  },
  {
    title: "Games & Toys",
    icon:`${Game}`
  },
];

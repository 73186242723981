import { useEffect, useState } from "react";
import "./pamo-zone.scss";
import { fetchApi } from "../../../service/services";
import ContentShimmer from "react-content-shimmer";
import { Container } from "react-bootstrap";

interface BannerItem {
  id: number;
  acf: {
    flyer_upload: string;
  };
}

export const ZoneFlyer = () => {
  const [bannerFlyer, setBannerFlyer] = useState<BannerItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchApi("pamo_zone_banner")
      .then((data) => {
        setBannerFlyer(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, []);

  return (
    <Container fluid className="zone-banner mt-5">
        {isLoading ? (
          <div
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="500"
            data-aos-once="false"
            data-aos-anchor-placement="down-center"
          >
            <ContentShimmer
              style={{ marginTop: "1rem", width: "w-100", height: "500px" }}
              rounded={"10px"}
            />
          </div>
        ) : (
          <div
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="500"
            data-aos-once="false"
            data-aos-anchor-placement="down-center"
          >
            {bannerFlyer.map((data, key) => (
              <div key={key} className="image-container">
                <img
                  src={data?.acf.flyer_upload}
                  key={data?.id}
                  style={{ width: "100%", height: "60vh",objectFit:"cover", objectPosition:"top" }}
                  className="rounded-5 zone-image"
                />
              </div>
            ))}
          </div>
        )}
    </Container>
  );
};

import ContentShimmer from "react-content-shimmer";

export const ShimmerCard = () => {
  return (
    <div
      className="item-card  mt-5 rounded-5   mx-3"
      style={{ width: "200px" }}
    >
      <div className="p-4 d-flex for-desktop">
        <ContentShimmer
          style={{ width: "150px", height: "150px", marginRight: "15px" }}
          rounded={"20px"}
        />
        <div>
          <ContentShimmer
            style={{ width: "200px", height: "15px", marginRight: "15px",marginTop:"30px" }}
            rounded={"20px"}
          />
          <ContentShimmer
            style={{ width: "300px", height: "15px", marginBottom: "5px" , marginTop: "5px"}}
            rounded={"20px"}
          />
          <ContentShimmer
            style={{ width: "100%", height: "15px", marginRight: "15px" ,marginBottom: "15px"}}
            rounded={"20px"}
          />
          <ContentShimmer
            style={{ width: "50%", height: "15px", marginRight: "15px" }}
            rounded={"20px"}
          />
        </div>

        
      </div>
      <div className="p-4 d-inline for-mobile">
        <ContentShimmer
          style={{ width: "75vw", height: "150px", marginRight: "15px" }}
          rounded={"20px"}
        />
        <div>
          <ContentShimmer
            style={{ width: "200px", height: "15px", marginRight: "15px",marginTop:"30px" }}
            rounded={"20px"}
          />
          <ContentShimmer
            style={{ width: "300px", height: "15px", marginBottom: "5px" , marginTop: "5px"}}
            rounded={"20px"}
          />
          <ContentShimmer
            style={{ width: "100%", height: "15px", marginRight: "15px" ,marginBottom: "15px"}}
            rounded={"20px"}
          />
          <ContentShimmer
            style={{ width: "50%", height: "15px", marginRight: "15px" }}
            rounded={"20px"}
          />
        </div>
      </div>
    </div>
  );
};

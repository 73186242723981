import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Card, Image } from "react-bootstrap";
import { IoIosArrowDown } from "react-icons/io";
import { fetchApi } from "../../../../../service/services";
import ContentShimmer from "react-content-shimmer";
import { HashLink as Link } from "react-router-hash-link";
import "./MakeAWish.scss";
import Lottie from "react-lottie-player";
import { Convenient, Handshake, Ward } from "../../../../../assets/images";

interface BannerItem {
  id: number;
  acf: {
    flyer_upload: string;
  };
}

export const MakeAWish = () => {
  const [bannerFlyer, setBannerFlyer] = useState<BannerItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hovered, setHovered] = useState(false);
  const animationData = hovered ? Convenient : Handshake;

  useEffect(() => {
    fetchApi("banner_wishlist")
      .then((data) => {
        setBannerFlyer(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, []);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  if (isLoading) {
    return (
      <Container>
        <ContentShimmer
          style={{ marginTop: "1rem", width: "w-100", height: "500px" }}
          rounded={"10px"}
        />
      </Container>
    );
  } else if (!isLoading && Object.keys(bannerFlyer).length === 0) {
    return (
      <div className="container-fluid wish-section">
        <div className="mt-lg-5">
          <div className="banner-bggg">
            <Row className=" flex-lg-row-reverse flex-column-reverse align-items-center pt-5 ">
              <div className="d-flex justify-content-start d-lg-block col-lg-4 mx-auto mt-5 mt-lg-0 hovery-container">
                {/* <img src="https://images.unsplash.com/photo-1530435460869-d13625c69bbf?crop=entropy&amp;cs=tinysrgb&amp;fit=crop&amp;fm=jpg&amp;ixid=MnwzNzg0fDB8MXxzZWFyY2h8MTB8fHdlYnNpdGV8ZW58MHwwfHx8MTYyMTQ0NjkyNg&amp;ixlib=rb-1.2.1&amp;q=80&amp;w=1080&amp;h=768" className="d-block mx-lg-auto img-fluid" alt="" loading="lazy"/> */}
                <div className="ms-3">
                  <Card className="wish-card-hovery m-2 p-3 ">
                    <Card.Body className="position-relative">
                      <div className=" rounded-circle text-end" style={{marginTop: "-27px", marginRight: "-18px"}}>
                        <div className="rounded-circle bg-info spot d-inline-block p-2"></div>
                      </div>
                      {/* <div className="alternating-images-on-hover"></div> */}
                      <div>
                        <Lottie
                          loop
                          animationData={animationData}
                          play={!hovered}
                          className="handshake"
                          // onMouseEnter={handleMouseEnter}
                          // onMouseLeave={handleMouseLeave}
                        />
                      </div>
                    </Card.Body>
                    <Card.Footer className="wish-post-made text-center">
                      Wish granted!
                    </Card.Footer>
                  </Card>
                </div>
              </div>
              <div className="col-lg-7 col-xl-6 px-4 pt-lg-5 d-flex flex-column justify-content-center">
                <div className="mb-3 pt-lg-1 text-header">
                  <div>
                    <h2 className="text-white text-center text-lg-start display-4 header-font">
                      {" "}
                      <span className="fw-bold text-break">
                        Make a wish
                      </span>{" "}
                      let your buddies sort you out with{" "}
                      <span className="fw-bold text-break">Pamo wishlist!</span>
                    </h2>
                  </div>
                </div>

                <div className="mb-1b font">
                  <div>
                    <p className="lead fs-4 text-center text-lg-start text-white ">
                      Let your wish come true.
                    </p>
                  </div>
                </div>

                <div className="d-flex align-items-center flex-wrap justify-content-center justify-content-lg-start mt-0">
                  <div className="d-flex flex-wrap btn-ward-section flex-md-row justify-content-center justify-content-lg-start position-relative align-items-center font">
                    <div className="order-md-1  font">
                      <Link to="https://bit.ly/PamoApp">
                        <Button
                          variant="info"
                          className="magic-ward-btn fw-bold text-white py-4 px-3 text-left w-100 magic-btn"
                          style={{
                            width: "145px",
                          }}
                        >
                          
                            Make a wish
                        
                        </Button>{" "}
                      </Link>
                    </div>
                    <div className="magic-ward order-md-2 wish">
                      <Lottie
                        loop
                        animationData={Ward}
                        play
                        style={{
                          width: "145px",
                          height: "100%",
                          marginLeft: "-38%",
                          transform: "scaleX(-1)", // Flip horizontally
                        }}
                        className="animate-lottie"
                      />
                    </div>
                  </div>
                  <div className="scroll-down-to-section my-3 text-center text-lg-start make-wish-section wish-step">
                    <Link
                      smooth
                      to="#make-wish"
                      className="text-white fw-normal text-link text-decoration-underline mx-1"
                    >
                      How to make your first wish
                    </Link>
                  </div>
                </div>
              </div>
            </Row>
            <div className="p-3 text-end position-relative">
              <Link smooth to="#make-wish">
                <Button className="p-4 rounded-circle btn-glass border-0">
                  <IoIosArrowDown className="text-white fw-bolder fs-2" />
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <Container id="promo-section">
        {bannerFlyer.map((item, key) => (
          <div className="promo-banner" key={key}>
            <Image
              className="flyer"
              src={item?.acf?.flyer_upload}
              key={item?.id}
            />
          </div>
        ))}
      </Container>
    );
  }
};

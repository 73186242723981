import { ZoneBanner } from "./zone_banner";
import { ZoneFlyer } from "./zone_flyer";
import { TrendProductCards } from "../home/components/trend-products/TrendProductCards";
import { BlogCard } from "./BlogCard";
import { Footer } from "../common/footer/Footer";
import ZoneVideo from "./zone_videos";
import './pamo-zone.scss'

export const PamoZone = () => {
  return (
    <div className="wrapper">
      <ZoneBanner />
      <ZoneVideo />
      <BlogCard start={0} end={4} />
      <TrendProductCards />
      <ZoneFlyer />
      {/* <BlogCard start={4} end={8} /> */}
      <div className="mt-5">
        <Footer />
      </div>
    </div>
  );
};

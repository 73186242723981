import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import "./trendproduct.scss";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { fetchApi } from "../../../../../service/services";
import { ShimmerItemCard } from "./shimmer";
import { ProductData } from "./product-data";
import { ArrowPointer } from "../../../common/navigating-arrow/ArrowPointer";

interface productItem {
  id: number;
  title: title;
  content: content;
  acf: acf;
  better_featured_image: better_featured_image;
}

interface title {
  rendered: string;
}

interface content {
  rendered: string;
}

interface acf {
  amount: string;
}

interface better_featured_image {
  source_url: string;
}

function getRandomColor() {
  const letters = ["#FFF29B", "#E8E6FE", "#FFE8CC", "#DDDCF5"];
  return letters[Math.floor(Math.random() * letters.length)];
}

export const TrendProductCards = () => {
  const [products, setProucts] = useState<productItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchApi("trend_product")
      .then((data) => {
        setProucts(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, []);

  const shimmerItems = Array.from({ length: 4 }, (item, index) => (
    <ShimmerItemCard key={index} />
  ));

  console.log("products", products);

  return (
    <>
      {isLoading ? (
        <Container>
          <div className="shimmer-parent d-flex overflow-auto">
            {shimmerItems}
          </div>
        </Container>
      ) : (
        <section
          className="trend-product-section"
          style={{ marginTop: "200px", marginBottom: "200px" }}
        >
          <Row className="trend-product-parent-container d-flex flex-nowrap overflow-auto">
            {ProductData.map((data, key) => (
              <Col
                className="trend-product-col"
                md={4}
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="500"
                data-aos-once="true"
              >
                <Card
                  className="trend-product-showcard p-4 my-5 fw-bold rounded-5 border-0 position-relative"
                  style={{ background: data.background }}
                  key={key}
                >
                  <div className="bold-text-name px-4 py-2 fs-1">
                    {data?.name}
                  </div>
                  <div className="px-3">
                    <Card.Img
                      src={data.image}
                      className="trend-product-image-showcase "
                    />
                  </div>
                  <div className="floating-btn-area mx-3">
                    <Button variant="" className="rounded-circle btn-circle">
                      <a href="#" className="text-white fs-6 ">
                        <MdOutlineArrowForwardIos />
                      </a>
                    </Button>
                  </div>
                </Card>
              </Col>
            ))}

            {products.map((data, key) => (
              <Col
                className="trend-product-col"
                md={4}
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="500"
                data-aos-once="true"
              >
                <Card key={key} className="p-4   my-5 trend-product-card">
                  {/* <div className="bold-text-name">{data?.title?.rendered}</div> */}
                  <div
                    className="trend-product-image-background rounded-5 px-4 py-4"
                    style={{ background: getRandomColor() }}
                  >
                    <Card.Img
                      src={data?.better_featured_image?.source_url}
                      className="trend-product-image"
                    />
                  </div>
                  <Card.Body>
                    <div className="trend-product-title fw-bold">
                      {data?.title?.rendered}
                    </div>
                    <div
                      className="trend-product-body fw-light"
                      dangerouslySetInnerHTML={{
                        __html: data?.content?.rendered,
                      }}
                    />
                    
                  </Card.Body>
                  <Card.Footer className="p-0 m-0 border-0 bg-transparent  ">
                  <div className="trend-product-amount text-uppercase ">
                      {data?.acf?.amount}
                    </div>
                    <div className="text-end fw-bold trend-link">
                      <a
                        href="https://bit.ly/PamoApp"
                        className="text-primary text-decoration-underline "
                      >
                        I want this!
                      </a>
                    </div>
                  </Card.Footer>
                </Card>
                {/* )} */}
              </Col>
            ))}
          </Row>
          <ArrowPointer />
        </section>
      )}
    </>
  );
};

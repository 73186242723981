import { Navigate, Route, Routes } from "react-router-dom";
import WebPageIndex from "../presentation/shared/Webpageindex";
import { Home } from "../presentation/main/home/Home";
import { WishList } from "../presentation/main/wishlist/WishList";
import { SellWithPamo } from "../presentation/main/sell-with-pamo/SellWithPamo";
import { BuyOnPamo } from "../presentation/main/buy-on-pamo/BuyOnPamo";
import { PamoZone } from "../presentation/main/pamo-zone/PamoZone";
import { Privacy } from "../presentation/main/privacy/Privacy";
import { TermsAndConditions } from "../presentation/main/terms-and-condition/TermsAndConditions";
import { MerchantGuide } from "../presentation/main/sell-with-pamo/component/merchant-guide/MerchantGuide";
import { BlogContent } from "../presentation/main/pamo-zone/BlogContent";


const AppRouting = () => {
  return (
    <Routes>
      {/* Landing area */}
      <Route path="/" element={<WebPageIndex />}>
        <Route index element={<Navigate replace to="home" />} />
        <Route path="home" element={<Home/>} /> 
        <Route path="buy-on-pamo" element={<BuyOnPamo/>} /> 
        <Route path="wishlist" element={<WishList/>} /> 
        <Route path="sell-with-pamo" element={<SellWithPamo/>} /> 
        <Route path="privacy" element={<Privacy/>} /> 
        <Route path="terms-and-conditions" element={<TermsAndConditions/>} /> 
        <Route path="merchant-guide" element={<MerchantGuide/>} /> 
        <Route path="blog-article/:id" element={<BlogContent/>} /> 
      </Route>

      {/* Not found */}
      <Route path="/pamo-zone" element={<PamoZone/>}></Route>
      <Route path="*" element={<h1>Page not found</h1>}></Route>

    </Routes>
  );
};

export default AppRouting;
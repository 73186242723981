export const QuickStepData = [
  {
    id: "1",
    title: `Tap "Make Offer"`,
    background:"#DDDCF5",
    body: [
      {
        list: `Easily spot the "Make an Offer" button on the Pamo app.`,
      },
      {
        list: `This is your gateway to close your sale!`,
      },
    ],
  },
  {
    id: "2",
    title: `Fill Required Details`,
    background:"#FFE8CC",
    body: [
      {
        list: `Select the product you wish to offer to the buyer.`,
      },
      {
        list: `Specify the price of your product`,
      },
      {
        list: `Attach a clear captivating photo to highlight your item's best features.`,
      },
      {
        list: `Choose the preferred delivery mode.`,
      },
      {
        list: `Provide the pickup location if applicable, ensuring convenience for both parties.`,
      },
    ],
  },
  {
    id: "3",
    title: `Finalize & Tap "Make Offer"`,
    background:"#E8E6FE",
    body: [
      {
        list: `Review your offer to ensure all the information is accurate.     `,
      },
      {
        list: `When everything looks perfect, hit "Make an Offer" to send your proposal to the buyer.`,
      },
      {
        list: `Now, it's time to seal the deal and turn potential customers into satisfied clients!`,
      }
    ],
  },
];

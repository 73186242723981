// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hover-card-parent {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 400px;
  transition: transform 0.3s ease; }

.hover-card-parent:hover {
  transform: translateY(-25px); }

.hover-header-text {
  font-size: 45px; }

.hover-card-text-area {
  height: auto; }

.hover-card-body-list {
  font-size: 16px; }

.hover-parent-header {
  margin-top: 10%; }

.hover-card-container {
  margin-bottom: 10%; }

.hover-steps-header {
  font-size: 45px; }

.wish-hover-card-wrapper {
  display: flex;
  flex-wrap: nowrap;
  height: 20rem;
  width: 100%; }

.header-font {
  font-family: "Clash Display"; }

@media (max-width: 470px) {
  .hover-card-parent {
    width: 350px !important; } }
`, "",{"version":3,"sources":["webpack://./src/presentation/main/wishlist/components/hover-cards/wish-hover-cards.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,YAAY;EACZ,+BAA+B,EAAA;;AAKjC;EACE,4BAA4B,EAAA;;AAG9B;EACE,eAAe,EAAA;;AAGjB;EACE,YAAY,EAAA;;AAId;EACE,eAAe,EAAA;;AAGjB;EACE,eAAe,EAAA;;AAGjB;EACE,kBAAkB,EAAA;;AAGpB;EACE,eAAe,EAAA;;AAGjB;EACE,aAAa;EACb,iBAAiB;EACjB,aAAa;EACb,WAAW,EAAA;;AAGb;EACE,4BAA4B,EAAA;;AAG9B;EACE;IACE,uBAAuB,EAAA,EACxB","sourcesContent":[".hover-card-parent {\n  display: flex;\n  flex-direction: column;\n  flex-wrap: nowrap;\n  width: 400px;\n  transition: transform 0.3s ease;\n  // height: 100% !important;\n  // max-height:auto;\n}\n\n.hover-card-parent:hover {\n  transform: translateY(-25px);\n}\n\n.hover-header-text {\n  font-size: 45px;\n}\n\n.hover-card-text-area {\n  height: auto;\n  // overflow-x: auto;\n}\n\n.hover-card-body-list {\n  font-size: 16px;\n}\n\n.hover-parent-header {\n  margin-top: 10%;\n}\n\n.hover-card-container {\n  margin-bottom: 10%;\n}\n\n.hover-steps-header {\n  font-size: 45px;\n}\n\n.wish-hover-card-wrapper {\n  display: flex;\n  flex-wrap: nowrap;\n  height: 20rem;\n  width: 100%;\n}\n\n.header-font {\n  font-family: \"Clash Display\";\n}\n\n@media (max-width: 470px) {\n  .hover-card-parent {\n    width: 350px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

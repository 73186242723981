import React from 'react'
import { BannerCard } from './components/banner/BannerCard'
import { TutorialCards } from './components/wish-tut/Tutorial'
import { MakeAWish } from './components/wishlistBanner/MakeAWish'

export const WishList = () => {
  return (
   <React.Fragment>
    {/* <BannerCard/> */}
    <MakeAWish/>
    <TutorialCards/>
   </React.Fragment>
  )
}


       // api call function
       export async function fetchApi(route:any) {
        const url = `${process.env.REACT_APP_BASE_URL}/${route}`;
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const result= Array.isArray(data) ? data : [data];
        console.log("data:", result)
        return result;
      }
    
  



   
import {Shoe,Tv,Phone,Bag} from '../../../../../assets/images/index'

export const ProductData = [
    {
        name:"Trend Products",
        title:"",
        image:`${Shoe}`,
        background:"#FFF29B",
        body:"",
        amount:"",
        link:"/"
    },
]
import { Container, Row, Col, Card } from "react-bootstrap";
import "./tutorial.scss";
import { Tutorial } from "./tut-data";
import { ArrowPointer } from "../../../common/navigating-arrow/ArrowPointer";
import { WishHoverCards } from "../hover-cards/WishHoverCards";

export const TutorialCards = () => {
  return (
    <Container
      fluid
      id="make-wish"
      style={{ marginTop: "200px", marginBottom: "150px" }}
    >
      <div className="tutorial-parent-header">
        <p
          className="tutorial-header-text fw-bold text-center my-5"
          // data-aos="fade-up"
          // data-aos-easing="linear"
          // data-aos-duration="500"
          // data-aos-once="false"
        >
          <div className="header-font">
          <h2 className="rfs-25 fw-bolder fs-1">Make a wish in 3 easy steps</h2>
          </div>
        </p>
      </div>
      <div className="tutorial-card-container d-flex justify-content-between justify-content-lg-center flex-nowrap overflow-auto">
        {Tutorial.map((data, key) => (
          <div key={key} className="mx-3 my-5">
            <WishHoverCards data={data} />
          </div>
        ))}
      </div>
      <ArrowPointer />
    </Container>
  );
};

import React from "react";
import { Outlet } from "react-router-dom";
import { Footer } from "../main/common/footer/Footer";
import { NavbarHeader } from "../main/common/navbar/Navbar";
import ContactUs from "../main/common/contact-us/ContactUs";

const WebPageIndex = () => {
  return (
    <section className="main-section-for-all">
      <NavbarHeader />
      <Outlet />
      {/* <ContactUs/> */}
      <Footer />
    </section>
  );
};
export default WebPageIndex;
import ContentShimmer from "react-content-shimmer";

export const ShimmerItemCard = () => {
  return (
    <div
      className="item-card w-100 mt-5 rounded-5 mb-5 bg-light mx-3"
      style={{ width: "200px" }}
    >
      <div className="p-4">
        <ContentShimmer
          style={{ width: "230px", height: "150px", marginRight: "15px" }}
          rounded={"20px"}
        />
      </div>
      <div className="px-4">
        <ContentShimmer
          style={{ width: "60%", height: "15px", marginRight: "15px" }}
          rounded={"20px"}
        />
      </div>
      <div className="px-4 mt-2">
        <ContentShimmer
          style={{ width: "100%", height: "15px", marginBottom: "5px" }}
          rounded={"20px"}
        />
        <ContentShimmer
          style={{ width: "100%", height: "15px", marginRight: "15px" }}
          rounded={"20px"}
        />
      </div>
      <div className="px-4 mt-4 mb-5">
        <ContentShimmer
          style={{ width: "50%", height: "15px", marginRight: "15px" }}
          rounded={"20px"}
        />
      </div>
    </div>
  );
};

import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { fetchApi } from "../../../service/services";
import { ShimmerCard } from "./shimmer";
import { Link } from "react-router-dom";
import "../../../index.scss"

interface BlogItem {
  id: number;
  title: {
    rendered: string;
  };
  content: {
    rendered: string;
  };
  excerpt: {
    rendered: string;
  };
  better_featured_image: {
    source_url: string;
  };
  acf: {
    date: string;
  };
}

export const BlogCard = ({ start, end }: { start: number; end: number }) => {
  const [blog, setBlog] = useState<BlogItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchApi("pamo_zone_blog")
      .then((data) => {
        setBlog(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, []);

  const shimmer = Array.from({ length: 4 }, (item, index) => (
    <Col md={6} sm={12} xs={12}>
      <ShimmerCard key={index} />
    </Col>
  ));

  return (
    <Container fluid className="blog-card-container mb-5">
      <div className="fw-bold mb-5 text-black blog-header header-font">
        
        <h2 className="rfs-25 fw-bolder fs-1 text-left">Pamo Blog</h2>

      </div>
      {isLoading ? (
        <Row>{shimmer}</Row>
      ) : (
        <Row className="gy-5">
  {blog.slice(start, end).map((item, key) => (
    <Col
      lg={6}
      md={12}
      sm={12}
      xs={12}
      // data-aos="fade-up"
      // data-aos-easing="linear"
      // data-aos-duration="500"
      // data-aos-once="false"
      // data-aos-anchor-placement="down-center"
    >
      <Link className="text-black" to={`/blog-article/${item.id}`}>
        <div className="d-flex align-items-start blog-card-structure">
          <div>
            <img
              src={item?.better_featured_image?.source_url}
              className="rounded-5 blog-image"
              style={{
                width: "200px",
                height: "180px",
                objectFit: "cover",
                objectPosition: "top",
              }}
            />
          </div>
          <div className="d-inline mx-3 ">
            <div className="fw-bold mb-3 blog-title">{item?.title?.rendered}</div>
            <div
              className="card-text blog-body"
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
              }}
              dangerouslySetInnerHTML={{
                __html: item?.excerpt?.rendered,
              }}
            />
            <div>
              <a href="#" className="text-secondary fw-bold card-text">
                Read more...
              </a>
            </div>
          </div>
        </div>
      </Link>
    </Col>
  ))}
</Row>

      )}
    </Container>
  );
};

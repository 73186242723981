import { useState } from "react";
import { Image, Dropdown } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { DarkLogo } from "../.../../../../../assets/images/index";
import { NavLink, useLocation } from "react-router-dom";
import "./navbar.scss";
import { BsPhone } from "react-icons/bs";
import { HashLink as Link } from "react-router-hash-link";

const CustomNavLink = ({ to, children, onClick }: any) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <NavLink
      to={to}
      onClick={onClick}
      className={`nav-link text-white  ${isActive ? "active-indicator" : ""}`}
    >
      {children}
    </NavLink>
  );
};

export const NavbarHeader = () => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    if (expanded === true) {
      handleNavClick();
    } else {
      setExpanded(true);
    }
  };

  const handleNavClick = () => {
    setExpanded(false);
  };

  return (
    <>
      <Navbar
        expand="lg"
        bg="transparent"
        variant="transparent"
        className="align-items-center position-relative "
        id="navbar"
        expanded={expanded}
      >
        <Container fluid className="align-items-center navbar-container">
          <Navbar.Brand className="col-4 mt-4">
            <CustomNavLink to="/" onClick={handleNavClick}>
              <Image src={DarkLogo} className="website-logo" />
            </CustomNavLink>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="navbar-nav"
            className="mt-5"
            onClick={handleToggle}
          />
          <Navbar.Collapse id="navbar-nav d-flex align-items-center justify-content-between">
            <Nav className="ml-auto menu bg-secondary  mt-4 py-3 px-5 text-white rounded-5">
              <CustomNavLink to="/buy-on-pamo" onClick={handleNavClick}>
                Buy on Pamo
              </CustomNavLink>
              <CustomNavLink to="/wishlist" onClick={handleNavClick}>
                Wishlist
              </CustomNavLink>
              <CustomNavLink to="/sell-with-pamo" onClick={handleNavClick}>
                Sell with Pamo
              </CustomNavLink>
              <CustomNavLink to="/pamo-zone" onClick={handleNavClick}>
                Pamo Zone
              </CustomNavLink>
            </Nav>

            <Nav className="talk-to-us-container">
              <Dropdown>
                <Dropdown.Toggle
                  variant="primary"
                  id="talk-to-us-dropdown"
                  className="d-flex bg-primary py-2 px-3 rounded-5 align-items-center mt-4"
                >
                  <div className="bg-secondary rounded-circle p-2">
                    <BsPhone className="text-white phone-icon" />
                  </div>
                  <div className="mx-2 text-white">Talk to us</div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu border-0">
                  <div className="mx-4 my-3 border-bottom text-dark">
                    Contact:{" "}
                    <p className="text-primary   fw-bold">0599203040</p>
                  </div>

                  <Link
                    className="mx-4 my-3 text-secondary"
                    to="https://wa.me/message/BAD7RIBA5FHLA1"
                  >
                    WhatsApp
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tutorial-parent-header {
  margin-top: 3%; }

.tutorial-card-container {
  margin-bottom: 15%; }

.tutorial-header-text {
  font-size: 50px; }

.header-font {
  font-family: "Clash Display"; }

@media only screen and (max-width: 767px) {
  .tutorial-steps-header {
    font-size: 40px !important; }
  .tutorial-card-container {
    margin-top: -10%; }
  #make-wish {
    margin-top: 60px !important;
    margin-bottom: auto !important; } }

@media only screen and (max-width: 576px) {
  .tutorial-header-text {
    font-size: 30px !important; }
  .tutorial-card-container {
    margin-top: -10%; } }

@media only screen and (max-width: 440px) {
  .tutorial-header-text {
    font-size: 30px !important;
    margin: 5% 5% !important; }
  .tutorial-card-container {
    margin-top: -10%; } }
`, "",{"version":3,"sources":["webpack://./src/presentation/main/wishlist/components/wish-tut/tutorial.scss"],"names":[],"mappings":"AAAA;EACE,cAAc,EAAA;;AAGhB;EACE,kBAAkB,EAAA;;AAGpB;EACE,eAAe,EAAA;;AAGjB;EACE,4BAA4B,EAAA;;AAG9B;EACE;IACE,0BAA0B,EAAA;EAE5B;IACE,gBAAgB,EAAA;EAElB;IACE,2BAA2B;IAC3B,8BAA8B,EAAA,EAC/B;;AAGH;EACE;IACE,0BAA0B,EAAA;EAE5B;IACE,gBAAgB,EAAA,EACjB;;AAGH;EACE;IACE,0BAA0B;IAC1B,wBAAwB,EAAA;EAE1B;IACE,gBAAgB,EAAA,EACjB","sourcesContent":[".tutorial-parent-header {\n  margin-top: 3%;\n}\n\n.tutorial-card-container {\n  margin-bottom: 15%;\n}\n\n.tutorial-header-text {\n  font-size: 50px;\n}\n\n.header-font {\n  font-family: \"Clash Display\";\n}\n\n@media only screen and (max-width: 767px) {\n  .tutorial-steps-header {\n    font-size: 40px !important;\n  }\n  .tutorial-card-container {\n    margin-top: -10%;\n  }\n  #make-wish {\n    margin-top: 60px !important;\n    margin-bottom: auto !important;\n  }\n}\n\n@media only screen and (max-width: 576px) {\n  .tutorial-header-text {\n    font-size: 30px !important;\n  }\n  .tutorial-card-container {\n    margin-top: -10%;\n  }\n}\n\n@media only screen and (max-width: 440px) {\n  .tutorial-header-text {\n    font-size: 30px !important;\n    margin: 5% 5% !important;\n  }\n  .tutorial-card-container {\n    margin-top: -10%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { Container, Row, Col } from "react-bootstrap";
import { PostData } from "./post-data";
import { HoverCard } from "../../../sell-with-pamo/component/hover-cards/HoverCards";
import { ArrowPointer } from "../../../common/navigating-arrow/ArrowPointer";
import { BuyHoverCards } from "../hover-cards/BuyHoverCards";
import "./PostWithPamo.scss";

export const PostWithPamo = () => {
  return (
    <Container fluid className="post-with-pamo" style={{marginTop: "200px", marginBottom: "100px"}}>
      <div className="tutorial-parent-header text-center">
        <p
          className="tutorial-header-text post-header fw-bold text-center my-5 "
          // data-aos="fade-up"
          // data-aos-easing="linear"
          // data-aos-duration="500"
          // data-aos-once="false"
          
        >
          <div className="header-font">
            <h2 className="rfs-25 fw-bolder fs-1">
            Get an item you’re looking for in easy steps!

            </h2>
          </div>
        </p>
      </div>
      <div className="tutorial-card-container d-flex jusfify-content-start justify-content-lg-center flex-nowrap overflow-auto">
        {PostData.map((data, key) => (
          <div key={key} className="mx-3 my-5">
            <BuyHoverCards data={data} />
          </div>
        ))}
      </div>
      <ArrowPointer />
    </Container>
  );
};

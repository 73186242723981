// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arrow-container {
  margin-top: -15%;
  padding: 0px 10%;
  animation: scrollArrow 5s linear; }

.arrow-icon {
  color: rgba(77, 74, 74, 0.089);
  font-size: 50px; }
`, "",{"version":3,"sources":["webpack://./src/presentation/main/common/navigating-arrow/arrow-pointer.scss"],"names":[],"mappings":"AAUA;EACI,gBAAiB;EACjB,gBAAe;EACf,gCAAgC,EAAA;;AAGpC;EACI,8BAA6B;EAC7B,eAAe,EAAA","sourcesContent":["\n// @keyframes scrollArrow {\n//     0% {\n//       transform: (calc(100vh - 50px));\n//     }\n//     100% {\n//       transform: translateY(calc(100vh - 50px)); /* Adjust as needed */\n//     }\n//   }\n\n.arrow-container{\n    margin-top: -15% ;\n    padding:0px 10%;\n    animation: scrollArrow 5s linear;\n}\n\n.arrow-icon{\n    color:rgba(77, 74, 74, 0.089);\n    font-size: 50px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

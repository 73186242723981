export {default as DarkLogo} from './logo/dark-logo.png'
export {default as LightLogo} from './logo/light-logo.png'
export {default as Shoe} from './trend-products/shoe.png'
export {default as Tv} from './trend-products/tv.png'
export {default as Bag} from './trend-products/bag.png'
export {default as Phone} from './trend-products/phone.png'
export {default as Wallet} from './lottie-animation/wallet.json'
export {default as Secure} from './lottie-animation/secure.json'
export {default as Chat} from './lottie-animation/chat.json'
export {default as Convenient} from './lottie-animation/convenient.json'
export {default as TradeCard} from './lottie-animation/trade-card.json'
export {default as Ward} from './lottie-animation/ward.json'
export {default as Baby} from './categories/baby.png'
export {default as Computer} from './categories/computers.png'
export {default as Tools} from './categories/tools.png'
export {default as Machines} from './categories/machines.png'
export {default as Fashion} from './categories/fashion.png'
export {default as Fitness} from './categories/fitness.png'
export {default as Game} from './categories/game.png'
export {default as Music} from './categories/music.png'
export {default as Electronics} from './categories/electronics.png'
export {default as Automobile} from './categories/automobile.png'
export {default as Book} from './categories/book.png'
export {default as Gift} from './categories/gift.png'
export {default as Groceries} from './categories/groceries.png'
export {default as Pet} from './categories/pet.png'
export {default as Construction} from './categories/construction.png'
export {default as Cosmetic} from './categories/cosmetic.png'
export {default as Security} from './categories/security.png'
export {default as Office} from './categories/office.png'
export {default as Mobile} from './categories/mobile.png'
export {default as Adult} from './categories/adult.png'
export {default as Gifts} from './rewards/gift.svg'
export {default as Coupons} from './rewards/coupons.svg'
export {default as Delivery} from './rewards/free-delivery.svg'
export {default as Cashback} from './rewards/cashback.png'
export {default as Vip} from './rewards/vip.svg'
export {default as RedDress} from './banner/red-dress.png'
export {default as BlueBag} from './banner/blue-bag.png'
export {default as LadyOne} from './banner/lady-one.png'
export {default as LadyTwo} from './banner/lady-two.png'
export {default as Handshake} from './banner/handshake.json'
export {default as BabyWheel} from './alternative_images/baby.png'
export {default as AirFry} from './alternative_images/machine-1.png'
export {default as Headset} from './alternative_images/headset.png'
export {default as Lips} from './alternative_images/lips.png'
export {default as Pillow} from './alternative_images/pillow.png'
export {default as Shirt} from './alternative_images/shirt.png'
export {default as RedDress1} from './alternative_images/red-dress.png'
export {default as BlueBag1} from './alternative_images/blue-bag.png'
export {default as Juliana} from './merchants/juliana.png'
export {default as PamoApp} from './blog-images/pamo-app.png'
export {default as BannerAd} from './blog-images/bannerAd.png'
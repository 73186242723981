import React from "react";
import { Container } from "react-bootstrap";
import { Guide } from "./merchant-guide-data";

export const MerchantGuide = () => {
  return (
    <Container>
      {Guide.map((data, key) => (
        <div key={key}>
          <div className="fs-1 mb-3 mt-5 text-secondary fw-bold">{data?.title}</div>
          <div className="fs-5 mb-3">{data?.description}</div>
          <div className="fs-5 my-3">{data?.subTitle}</div>
          <div>
            <ul>
              {data?.points?.map((item, key) =>
                item?.item == "" ? "" : <li className="my-2" key={key}>{item.item}</li>
              )}
            </ul>
          </div>
        </div>
      ))}
    </Container>
  );
};

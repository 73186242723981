import React, { useState } from "react";
import { Container, Image } from "react-bootstrap";
import { CategoryData } from "./category-data";
import "./categories.scss";

export const Categories = () => {
  const colors = ["#DDDCF5", "#FFE8CC", "#D8DCFA", "#E6FEEB", "#98E8FC"];

  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };

  const CategoryItem = ({ data }: any) => {
    const [backgroundColor, setBackgroundColor] = useState("#F5F5F5");

    const cardBodyStyle = {
      backgroundColor,
    };

    const handleMouseEnter = () => {
      setBackgroundColor(getRandomColor());
    };

    const handleMouseLeave = () => {
      setBackgroundColor("#F5F5F5");
    };

    return (
      <Container className="d-flex flex-wrap categories-container">
          <div
        className=" categories-images-container mx-3 my-5 text-center rounded-circle"
        style={cardBodyStyle}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Image src={data.icon} className="category-image" />
        <div
          className="categories-title fw-light"
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="700"
          data-aos-once="false"
          data-aos-anchor-placement="down-center"
        >
          {data.title}
        </div>
      </div>
      </Container>
      
    );
  };

  return (
    <Container fluid className="categories-section">
      <div className="categories-header fw-bold">
      <h2 className="rfs-25 fw-bolder fs-1">
      Categories</h2>
        </div>
      <div className="d-flex flex-wrap  category-items">
        {CategoryData.map((data, key) => (
          <div
            className="category-item-container"
            key={key}
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="500"
            data-aos-once="false"
            data-aos-anchor-placement="down-center"
          >
            <CategoryItem key={key} data={data} />
          </div>
        ))}
      </div>
    </Container>
  );
};

export const TermsConditionsData = [
  {
    listName: "About Pamo",
    list: [
      {
        item: "Pamo is a buyer-led marketplace that facilitates the sale, purchase, and delivery of all kinds of goods from across the world. The sale of goods contract is between the buyers and sellers and not Pamo and the users. While we may help facilitate the resolution of disputes between users, Pamo has no control over and does not guarantee: the existence, quality, safety, or legality of items advertised; the truth or accuracy of users' content or listings; the ability of sellers to sell items; the ability of buyers to pay for items; or that a buyer or seller will actually complete a transaction or return an item.",
      },
    ],
  },
  {
    listName: "User Representation and Warranties",
    list: [
      {
        item: "By creating an account and using the Pamo platform you confirm that you accept these Terms. You also represent and warrant that:",
      },
      {
        item: "You have legal capacity;",
      },
      {
        item: "You are 18 years or older; or",
      },
      {
        item: "You are 13 or older but younger than 18, that you have reviewed these Terms with your parent or legal guardian, and that you and your parent or guardian understand and consent to the terms and conditions of these Terms. If you are a parent or guardian permitting a minor to use these Services, you agree to (i) supervise the minor’s use of these Services; (ii) assume all risk associated with the minor’s use of the Services; (iii) assume any liability resulting from the minor’s use of the Services; (iv) ensure the accuracy and truthfulness of all information submitted by the minor and (v) assume responsibility and be bound by these Terms for the minor’s access and use of these Services.",
      },
      {
        item: "You will not make a post to request something illegal/prohibited.",
      },
      {
        item: "You will not respond to a post that is demanding prohibited goods.",
      },
      {
        item: "All information you supplied about your identity and location is true;",
      },
      {
        item: "You will notify Pamo of any unauthorized access to your account;",
      },
      {
        item: "You will not make posts on the Platform that infringe another person’s rights or interests, including any intellectual property rights or any other personal or proprietary rights of any third party.",
      },
    ],
  },
  {
    listName: "Account Registration",
    list: [
      { item: "Online registered users may buy or sell items on Pamo." },
      {
        item: "A registered user may function as a buyer in one circumstance and as a seller in another as the case may be.",
      },
      {
        item: "To register as a user, you must provide a unique username (which is your phone number in this case) and a pin (which functions as your password) and provide certain information and personal details to Pamo. You will need to use your unique username and password to access Pamo in order to make a post, make a purchase or to sell an item.",
      },
      { item: "You agree and warrant that your username and password shall:" },
      { item: "be used for personal use only; and" },
      { item: "not be disclosed by you to any third party" },
      {
        item: "You must link a valid payment method to your registered account.",
      },
      {
        item: "You may be asked to provide your location at the time of registration.",
      },
      {
        item: "You will use this service in accordance with the applicable laws. Regulations etc.",
      },
      {
        item: "You will notify Pamo of any unauthorized access to your account;",
      },
      {
        item: "You will not make posts on the Platform that infringe another person’s rights or interests, including any intellectual property rights or any other personal or proprietary rights of any third party.",
      },
      { item: "There are 3 categories of users:" },
      {
        item: "Generic user - they may buy or sell on Pamo subject to these Terms. A generic User makes posts of the items they wish to buy with the categories the items fall in;",
      },
      {
        item: "Business - they have items to sell in response to a User’s post subject to these Terms",
      },
      {
        item: "Verified business - they are businesses verified by Pamo that may respond to buyer posts with the items they have for sale. A verified business will receive notifications from Pamo each time a post is made for an item that they have available for sale. A business is verified on Pamo when it supplies Pamo with the following documents:",
      },
      { item: "Business Incorporation documents." },
      { item: "Current permit to operate in their industry." },
      { item: "Any other licenses/approvals your business may require." },
      {
        item: "The Verified businesses must indicate the manner in which they would like to be notified when a user is interested in the items they have for sale.",
      },
    ],
  },
  {
    listName: "Service",
    list: [
      {
        item: "Pamo is an online service that allows users to create and publish requests for items they wish to buy (‘posts’), to view posts published by other users, and to communicate with other users on the app.",
      },
      { item: "Registered Users may buy or sell at different points." },
      {
        item: "To register as a user, you must provide a unique username (which is your phone number in this case) and a pin (which functions as your password) and provide certain information and personal details to Pamo. You will need to use your unique username and password to access Pamo in order to make a post, make a purchase or to sell an item.",
      },
      {
        item: "Users who want to purchase an item may make a post containing the specifications of the item they are looking for including:",
      },
      { item: "the category of item." },
      { item: "the price they would like to pay for it." },
      { item: "A photograph of the item (optional)." },
      { item: "Preferred date of delivery." },
      {
        item: "Detailed description of the item e.g. colour, size, shape etc.",
      },
      {
        item: "Once the post is live, verified businesses looking to sell the items requested in the post will be notified by Pamo.",
      },
      {
        item: "Buyers must show proof of their ability to pay for the item once responses come in. This activates negotiations with the business or verified business.",
      },
      {
        item: "Acceptable proof is to fund the buyer’s pamo trade card with the amount the buyer is prepared to pay for the item. This will allow the buyer to engage with interested sellers/businesses and commence negotiations.",
      },
      {
        item: "Once the post is live, verified businesses looking to sell the items requested in the post will be notified by Pamo.",
      },
      {
        item: "A deal is reached once the two users accept each other's terms and agree to transact. The buyer will make the payment of the final agreed amount through Pamo. Pamo will hold the money in escrow and guarantee to the seller that the buyer is able to pay the negotiated amount.",
      },
      { item: "Pamo will hold the amount while delivery is arranged." },
      {
        item: "Upon receipt of the item, the buyer will confirm the receipt of the item on the Pamo platform, and the seller may also confirm delivery of the item. Pamo will then release the funds to the seller.",
      },
      {
        item: "If the buyer fails to confirm receipt of the item but the seller confirms delivery, the funds will be released to the seller within 48 hours of the confirmation.",
      },
    ],
  },
  {
    listName: "Fees and Taxes",
    list: [
      { item: "Use of the Pamo platform is free." },
      {
        item: "Third party card processors and payment service providers may charge users for use of their service on the platform.",
      },
      {
        item: "Payment for the items purchased and payment services accepted by Pamo are disclosed on the platform.",
      },
    ],
  },
  {
    listName: "Fees and Taxes",
    list: [
      {
        item: "Sellers on Pamo will not be allowed to post their items until a request for the items they sell is posted. At this point, Sellers may send responses to the specific user who made the post and not to the entire platform.",
      },
      {
        item: "Only buyers may make posts about the goods they need on the platform in order to be connected to sellers who have those items for sale.",
      },
      {
        item: "Pamo will leave a post live for 48 hours after a deal has been reached or until the delivery date provided in the post if no deal has been reached by the said date.",
      },
      {
        item: "Posting with a funded trade card will trigger verified business notifications immediately.",
      },
      { item: "A user may cancel a post if no deal is reached." },
      { item: "Any post that" },
      { item: "Contains nudity" },
      { item: "Is Abusive, offensive, or harmful" },
      { item: "Has an Illegal product." },
      { item: "Has an image that does not match the product’s description." },
      { item: "Is suspicious or has stolen items." },
      { item: "Has an unrealistic price." },
      { item: "Is considered inappropriate." },
    ],
  },
  {
    listName: "Reported Posts",
    list: [
      {
        item: "User can report posts that are offensive or sensitive. Reported posts are reviewed by the Pamo team and removed if they are found to violate our posting conditions. The Pamo team also reviews and removes post which violate our posting conditions. Users who continually violate our posting conditions may be banned from using the app.",
      },
    ],
  },
  {
    listName: "Purchase Conditions",
    list: [
      { item: "When buying an item on our Services, you agree that:" },
      {
        item: "It is your responsibility to read the full item listing before committing to buy.",
      },
      {
        item: "You enter into a legally binding contract to purchase an item when you commit to buy an item or agree to a deal with a seller/business.",
      },
      {
        item: "Not all users are vetted by Pamo before registration as such all deals reached between you and sellers/businesses are reached at the sole risk of the parties.",
      },
      {
        item: "Pamo does not guarantee the quality of goods bought using the Service.",
      },
    ],
  },
  {
    listName: "Trade Card",
    list: [
      {
        item: "We shall provide you with a closed-loop virtual trade card (“your trade card”) when you register with us. You may transfer funds to your trade card via any of the payment channels listed on Pamo. You may only purchase goods on Pamo with your trade card. You shall fund your trade card with an amount not less than the amount you intend to purchase an item with before you negotiate with a potential seller for such item.",
      },
    ],
  },
  {
    listName: "Payment",
    list: [
      {
        item: "There are no paid subscriptions on the app. The app only facilitates purchases/sales between anyone looking to buy a particular physical item and anyone who has the item for sale.",
      },
    ],
  },
  {
    listName: "Escrow",
    list: [
      {
        item: "Users agree to an escrow agreement when they use the Pamo platform.",
      },
      {
        item: "This means that they authorize Pamo to hold funds paid by a buyer for a period and to release it to the seller upon confirmation of receipt of goods by the buyer.",
      },
      {
        item: "When Users make payment on Pamo, it will be held in escrow until delivery of the purchased item has been confirmed by the buyer.",
      },
      {
        item: "Once the item is received and the buyer confirms this, Pamo will allow the money to be sent to the seller.",
      },
      {
        item: "Any dispute arising between users concerning this escrow will be resolved per the terms of the Legal Framework for Dispute Resolution.",
      },
    ],
  },
  {
    listName: "Additional Terms",
    list: [
      { item: "Cancellations, Returns and Refunds" },
      { item: "Buyers may cancel a deal for wrong or defective item." },
      {
        item: "Buyers may cancel a deal if the item is not delivered by the preferred delivery date as stated in the post.",
      },
      {
        item: "Sellers/businesses must confirm or dispute the cancellation claim by the buyer.",
      },
      {
        item: "If a seller/business confirms the reason for cancellation, it effectively reverses an order. This means funds are automatically and immediately returned to the buyer’s trade card.",
      },
      {
        item: "If a seller disputes the reason for cancellation, the dispute will be resolved per the terms in the Pamo Dispute Resolution Procedure.",
      },
      {
        item: "You agree to contract independently on the terms of returns between you and buyers or sellers/businesses as the case may be. Pamo’s involvement arises when money paid for an item is held in escrow by Pamo, this is released once delivery is confirmed or a dispute is resolved.",
      },
    ],
  },
  {
    listName: "Limitation of Liability",
    list: [
      {
        item: "PAMO’S SERVICES ARE OFFERED TO YOU ON AN ‘AS IS’ OR ‘AS AVAILABLE’ BASIS. YOU MAKE USE OF THE PAMO SERVICES AT YOUR OWN RISK. AS A RESULT, AS FAR AS PERMITTED BY THE APPLICABLE LAW, PAMO EXCLUDES ALL EXPRESS AND IMPLIED WARRANTIES, TERMS, CONDITIONS INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR PURPOSE. TO THE EXTENT PERMITTED BY THE APPLICABLE LAW, UNDER NO CIRCUMSTANCE WILL PAMO (INCLUDING ITS PARENT, SUBSIDIARIES, OFFICERS, DIRECTORS, AGENTS, EMPLOYEES) BE LIABLE TO YOU OR ANY THIRD PARTY UNDER ANY CLAIM IN EQUITY OR AT LAW FOR ANY CONSEQUENTIAL DAMAGES OR LOSSES (INCLUDING, BUT NOT LIMITED TO, LOSS OF MONEY, GOODWILL OR REPUTATION, PROFITS, OTHER INTANGIBLE LOSSES, OR ANY SPECIAL, INDIRECT, OR CONSEQUENTIAL DAMAGES), AND ALL SUCH DAMAGES OR LOSSES ARE EXPRESSLY EXCLUDED BY THIS AGREEMENT WHETHER OR NOT THEY WERE FORESEEABLE OR PAMO WAS ADVISED OF SUCH DAMAGES OR LOSSES. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE (INCLUDING OUR PARENT, SUBSIDIARIES, AND AFFILIATES, AND OUR AND THEIR OFFICERS, DIRECTORS, AGENTS AND EMPLOYEES) ARE NOT LIABLE, AND YOU AGREE NOT TO HOLD US RESPONSIBLE, FOR ANY DAMAGES OR LOSSES (INCLUDING, BUT NOT LIMITED TO, LOSS OF MONEY, GOODWILL OR REPUTATION, PROFITS, OTHER INTANGIBLE LOSSES, OR ANY SPECIAL, INDIRECT, OR CONSEQUENTIAL DAMAGES) RESULTING DIRECTLY OR INDIRECTLY FROM:",
      },
      { item: "Your use of or your inability to use our Services." },
      { item: "delays or disruptions in our Services;" },
      {
        item: "Sellers/businesses must confirm or dispute the cancellation claim by the buyer.",
      },
      {
        item: "viruses or other malicious software obtained by accessing or linking to our Services;",
      },
      {
        item: "glitches, bugs, errors, or inaccuracies of any kind in our Services;",
      },
      {
        item: "damage to your hardware device from the use of any Pamo Service;",
      },
    ],
  },
  {
    listName: "Ending These Terms",
    list: [
      { item: "BY YOU" },
      {
        item: "You may terminate these Terms at any time by deleting your account and stopping your use of the Services.",
      },
      { item: "BY PAMO" },
      {
        item: "We may suspend or terminate your Pamo account or stop providing you with access to any or all of our Services at any time, if:",
      },
      {
        item: "We reasonably suspect that you have violated these Terms or the instructions provided in our Services.",
      },
      {
        item: "You have clearly demonstrated (through your actions or statement or otherwise) that you do not intend to comply with these Terms.",
      },
      {
        item: "We decide to end all or part of our Services (either worldwide or in the country where you are resident or from where you access our Services).",
      },
      {
        item: "We are required by Applicable Law to end all or part of our Services (for example, if due to changes in Applicable Law or due to court rulings or judgments which make the Services or parts of them become or be considered unlawful).",
      },
    ],
  },
  {
    listName: "Intellectual Property Rights",
    list: [
      {
        item: "We and/or our content suppliers own the contents and/or compilation of the contents of the Platform and our Service, including any material, information, data compilations, software, icons, text, graphics, lay-outs, images, sound clips, advertisements, video clips, digital downloads, trade names, logos, trade-marks, designs and service marks (“Our Content”) and Our Content is protected by law, including but not limited to intellectual property laws. You do not acquire any right, title or interest in Our Content. You shall not use, distribute, reproduce, republish, install, transmit, modify, adapt, store, post, copy, screenshot or record our content without our prior permission unless expressly authorized in these Terms and Conditions or otherwise provided for in law.",
      },
      {
        item: "You own the information you submit to us as part of your registration, and any data, text, pictures and other materials that you upload, post, submit, store, send, receive or share on the Platform or with us (“Your Content”). We do not claim any intellectual property rights of Your Content. Notwithstanding any provision in these Terms and Conditions and subject to our Privacy Policy, you grant us non-exclusive, worldwide, transferable, perpetual, irrevocable right to use, distribute, reproduce, republish, install, transmit, modify, adapt, store, post, copy, display, communicate and retain copies Your Content in connection with the Service, marketing, advertising, and other purposes. You represent and warrant that your use of the Service or any portion thereof, Your Content and the right you grant us under this clause does not infringe the rights of any person or breach any contract or legal duty owed to any person.",
      },
      {
        item: "Any third party registered or unregistered intellectual property on the Platform (“Third Party Content”) is owned and controlled by that third party and we do not grant any license in respect of the Third Party Content. Your use of any Third Party Content is subject to these Terms and Conditions and any terms and conditions of the Third Party.",
      },
      {
        item: "If we have an honest belief that Your Content or part of Your Content infringes our or a third party’s intellectual property rights, we shall give you notice of such infringement and remove that content.",
      },
    ],
  },
  {
    listName: "Applicable Law & Jurisdiction",
    list: [
      {
        item: "These Terms and any dispute between you or your affiliates, predecessors, successors, assigns, employees and agents and us shall be governed by the laws of Ghana irrespective of the rules of conflict of laws. Any dispute arising out of these terms and conditions shall be settled by arbitration in accordance with the laws of Ghana and Ghana Arbitration Centre rules. The seat for the arbitration will be Accra, Ghana. The language for the arbitration shall be English. There shall be a sole arbitrator which shall be appointed by the Ghana Arbitration Centre.",
      },
    ],
  },
   {
    listName: "Miscellaneous Provisions",
    list: [
      {
        item: "These Terms and all terms and policies posted through our Services constitute the entire understanding and agreement between you and Pamo. It supersedes all prior understandings and agreements of the parties.If any provision of these Terms is held by an adjudicatory body of competent jurisdiction to be invalid, the remaining provisions shall remain effective and shall not be affected.",
      },
    ],
  },
  {
    listName: "Amendment",
    list: [
      {
        item: "These Terms are effective as of date and time it is posted on this page. They shall remain in effect except with respect to any changes in its provisions in the future, which shall be effective immediately after being published.We reserve the right to update or change our Terms. Your continued use of the Service after we post any modifications to these Terms will constitute your acknowledgment of the modifications and your consent to abide by and be bound by the modified Terms.If we make any material changes to the Terms, we will notify you either through the email address you have provided us or by placing a prominent notice on our application.",
      },
    ],
  },
  {
    listName: "Contact",
    list: [
      {
        item: "We may be reached by:",
      },
      {
        item: "Phone - +233 59 920 3040",
      },
      {
        item: "Email - business@pamo.app",
      },
    ],
  },
];

export const PrivacyData = [ 
    {
        listName :"Information Collection",
        List:[
            {
                item:"If you use the Platform as a User, we may collect:",
            },
            {
                item:"personal information which includes but not limited to your name, phone number and email address.",
            },
            {
                item:"and upload your contact list in order to notify other Pamo users about your wishes and allow them to contribute. This feature is only available if you allow the app to access your contact list. We value your privacy and will only use this data for the purpose stated.",
            },
            {
                item:"images from your camera or gallery to include in:",
            },
            {
                item:"creating your profile so users can see your profile picture in your posts",
            },
            {
                item:"making a post so your post is more descriptive",
            },
            {
                item:"making an offer so users can see images of the item you are offering",
            },
            {
                item:"Pamo can only access images from your camera or gallery after you allow access.",
            },
            {
                item:"your location so we can perform pickup and delivery of items for deals closed on Pamo.",
            },
            {
                item:"In addition to the information collected from a User:",
            },
            {
                item:"if you are a Verified Seller, we will collect information about your brand, reputation, website address, business including but not limited to registration documents, licenses and the products you intend to offer to sell on the Platform and any other necessary information needed for verification;",
            },
            {
                item:"if you are a Merchant, we will collect information about your business including but not limited to registration documents, licenses and the products you intend to offer to sell on the Platform; and",
            },
            {
                item:"If you are a Trained Agent, we will collect information about your educational background and professional experience.",
            },
            {
                item:"We will collect additional information which you voluntarily provide in response to our questionnaires including but not limited to nationality, sex, monthly spending and user preferences.",
            },
           
        ]
    },
    {
        listName :"Use of Information",
        List:[
            {
                item:"We use the information we collect from you for the following purposes: provide our services; maintain & improve our services; develop new services; measure performance; communicate with you; facilitate your communication with other Users of the Platform; verify your identity and Users’ identities of the Platform; set up User accounts; provide customer service support; process transactions and payments; provide delivery services; provide user benefits; verify transactions; prevent fraud, money laundering, cyber-attacks and other security incidents; conduct market survey; and other purpose which is not inconsistent with this Policy and any other law.",
            },
        ]
    },
    {
        listName :"Review",
        List:[
            {
                item:"When you sign in, you can review and update your information on the Platform.",
            },
        ]
    },
    {
        listName :"Data Export",
        List:[
            {
                item:"You can export a copy of your information if you want to back it up or use it with a service outside the Platform.",
            },
        ]
    },
    {
        listName :"Non-disclosure",
        List:[
            {
                item:"We do not share your personal information with companies, organizations, or individuals except in the following cases:",
            },
            {
                item:"We may disclose your Personal information to anyone with your consent;",
            },
            {
                item:"We may disclose your Personal information to other users of the platform where necessary to support transactions and communications made between you and other users.",
            },
            {
                item:"We may provide your Personal information to our affiliates and other trusted businesses or persons including but not limited to our payment service providers and our cloud computing service providers to process your Personal information for us, based on our instructions and in compliance with our Privacy Policy and any other appropriate confidentiality and security measures.",
            },
            {
                item:"We will share your Personal information if we have a good-faith belief that access, use, preservation, or disclosure of the information is reasonably necessary to meet any applicable law, regulation, legal process, or enforceable governmental request.",
            },
        ]
    },
    {
        listName :"Cookies",
        List:[
            {
                item:"A cookie is a small piece of data sent from a website and stored on your computer which allows websites to record your browsing activity and remember information. We use cookies: to provide you with personalized content; to make the relevant advertisements to you; to maintain your preferences; to enhance your experience on the platform; to track and prevent fraud, spam and abuse; and to understand how you engage with our platform.",
            },
        ]
    },
    {
        listName :"Data transfers",
        List:[
            {
                item:"We maintain servers around the world and your information may be processed on servers located outside of the country where you live. Data protection laws vary among countries, with some providing more protection than others. Regardless of where your information is processed, we apply the same protections described in this policy.",
            },
        ]
    },
    {
        listName :"Data Security",
        List:[
            {
                item:"We will implement appropriate measures to prevent unauthorized access to your Personal information and to maintain data integrity. We will notify you if we become aware of any unauthorized access to your Personal information. Transmission of information over the internet cannot be perfectly secured. We will use our best endeavours to secure your Personal information transmitted over the internet. However, we do not guarantee the security of your Personal information transmitted over the internet.",
            },
        ]
    },
    {
        listName :"Legal Basis for Data Processing",
        List:[
            {
                item:"We process personal information for the purposes set out in this Privacy Policy. Our legal basis for processing your personal information includes where it is necessary for the performance of contract between you and us, necessary to comply with legal requirements, necessary for our legitimate interests.",
            },
        ]
    },
    {
        listName :"Amendment",
        List:[
            {
                item:"This Policy is effective as of date and time it is posted on this page. This Policy shall remain in effect except with respect to any changes in its provisions in the future, which shall be effective immediately after being posted on this page. We reserve the right to update or change our Privacy Policy at any time and you should check this Policy periodically. Your continued use of the Service after we post any modifications to this Policy on this page will constitute your acknowledgment of the modifications and your consent to abide by and be bound by the modified Privacy Policy. If we make any material changes to this Policy, we will notify you either through the email address you have provided us or by placing a prominent notice on our website.",
            },
        ]
    },
]
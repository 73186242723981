export const Tutorial=[
    {
        id:"1",
        title:"Dream it, post it, Wish it!",
        body:"Share what you want with a post. Tap the make a wish button to turn your post into a wish!",
        background:"#DDDCF5"
    },
    {
        id:"2",
        title:"The Wishlist Adventure",
        body:"Track your wish's progress and see who's lending a hand. When your wish comes true, transform it back to a post!",
        background:"#FFE8CC"
    },
    {
        id:"3",
        title:"Seal the Deal, High Five the Buy!",
        body:"Vendors offer, the choice is yours. Pick your perfect deal and close the deal with a high-five!",
        background:"#E8E6FE"
    },
]
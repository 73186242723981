export const Guide = [
  {
    title: "About Pamo",
    description: `Pamo is a marketplace that facilitates the sale, purchase and delivery of diverse products and
        services from across the country. The existing product categories include but not limited to the
        following;`,
    subTitle: "",
    points: [
      { item: "Consumer Electronics" },
      { item: "Phones/ Tablets/Laptops" },
      { item: "Adult Items" },
      { item: "Fashion" },
      { item: "Books" },
      { item: "Baby Items" },
      { item: "Events" },
    ],
  },
  {
    title: "Value Proposition to Merchants",
    description: "",
    subTitle: "",
    points: [
      {
        item: "More Sales. Get easy access to a service that aggregates buyers for you.",
      },
      {
        item: "Access to our value-added services such as Insurance when you sell through us.",
      },
      { item: "Become responsive and efficient." },
      { item: "Effectively track all sales." },
    ],
  },
  {
    title: "Guidelines for Merchants.",
    description: `All merchants must act honestly and according to the following guidelines on Pamo to
        ensure a safe buying and selling experience.`,
    subTitle: "",
    points: [{ item: "" }],
  },
  {
    title: "Service Level Agreement (SLA’s)",
    description: ``,
    subTitle: "Purchase Process",
    points: [
      {
        item: "Acknowledge every request within 20 minutes of receipt of notification from Pamo.",
      },
      { item: "No cancellation after the deal has been confirmed." },
      {
        item: "Offer genuine products for every order that is within the buyer’s budget.",
      },
      { item: "Offer competitive rates to all buyers." },
    ],
  },
  {
    title: "",
    description: "",
    subTitle: "Delivery",
    points: [
      { item: "Deliver order specifications as agreed with the buyer." },
      {
        item: "Delivery cost must be included in all pricing shared with buyer.",
      },
      { item: "Adhere to timelines shared with buyer for delivery." },
      { item: "Bear cost of delivery for damaged or rejected items." },
    ],
  },
  {
    title: "",
    description: "",
    subTitle: "Dispute",
    points: [
      { item: "Acknowledge every dispute raised with 60 minutes." },
      { item: "Close all disputes within 48 hours." },
    ],
  },
  {
    title: "Communication",
    description: `Merchants may not send unsolicited or inappropriate messages. All communications to buyers must be
        sent through the Buyer-Seller Messaging and be necessary for fulfilling the order or providing buyer
        services. Marketing communications are prohibited.`,
    subTitle: "",
    points: [{ item: "" }],
  },
  {
    title: "Correct Information",
    description: `Merchants must provide accurate information to Pamo and its buyers and update the information if it
        changes. This means that merchants must only provide products that are within their assigned category.`,
    subTitle: "",
    points: [{ item: "" }],
  },
  {
    title: "Buyer Information",
    description: `Buyer information such as addresses or phone numbers received to fulfill orders must only be used to
        fulfill orders. Merchants may not use buyer information to contact buyers (except through Buyer-Seller
        Messaging) or share it with any third-party.`,
    subTitle: "",
    points: [{ item: "" }],
  },
  {
    title: "Circumventing the Sales Process",
    description: `Merchants may not attempt to circumvent the Pamo sales process or divert Pamo buyers to another
        website. This means that Merchants may not provide links or messages that prompt users to visit any
        external website or complete a transaction elsewhere.`,
    subTitle: "",
    points: [{ item: "" }],
  },
  {
    title: "Multiple Selling Accounts on Pamo",
    description: `Merchants may only maintain one Merchant account on Pamo and the account must be in good standing.
        Merchants can have multiple categories on one account. If the Merchant’s account is not in good standing,
        we may deactivate the account.`,
    subTitle: "",
    points: [{ item: "" }],
  },
  {
    title: "Acting Fairly",
    description: `Merchants must act fairly and lawfully and may not misuse any service provided by Pamo. Examples of
        unfair activities include:`,
    subTitle: "",
    points: [
      {
        item: `Providing misleading or inappropriate information to Pamo or its customers, such as posting
            offensive product images or sharing incorrect KYC information.`,
      },
      {
        item: `Attempting to increase the price of a product after an order is confirmed.`,
      },
      {
        item: `Allowing other people to act on Merchant’s behalf in a way that violates Pamo’s policies or the
            Merchant’s agreement with Pamo.`,
      },
    ],
  },
  {
    title: "Dispute Resolution",
    description: `Disputes raised because of a wrong or defective product delivered can be resolved by both parties
    (merchant & buyer) independently via the Pamo dispute resolution service. A mediator will be assigned to
    the issue if both buyer and merchant are unable to solve the issue. The assigned mediator will make the
    ultimate decision based on the evidence provided by both parties to enable a final resolution to the issue.`,
    subTitle: "",
    points: [{ item: "" }],
  },
  {
    title: "Rating",
    description: `Merchant may not attempt to influence or inflate buyers’ rating but may request ratings from their own
    buyers in a neutral manner. Merchants may not:`,
    subTitle: "",
    points: [
      {
        item: "Pay for or offer an incentive (free products or extreme discounts) in exchange for rating.",
      },
      {
        item: "Solicit ratings only from buyers who had a positive experience.",
      },
      { item: "Rate their own products or a competitors’ products." },
      { item: "Attempting to damage another Merchant’s reviews or ratings." },
    ],
  },
  {
    title: "Rating Metrics",
    description: `The rating metrics include:`,
    subTitle: "",
    points: [
      { item: "Pricing" },
      {
        item: "Buyer Satisfaction (considering communication after buyer acceptance empathy)",
      },
      { item: "Product Quality" },
      { item: "Packaging" },
      { item: "Delivery" },
    ],
  },
  {
    title: "Merchant Status",
    description: `Every 6 months, Pamo will evaluate Merchant performance over the period for all sales and ratings on
    their account. Each bi-annual assessment is a 7-day period beginning on:`,
    subTitle: "",
    points: [
      { item: "2nd week of January" },
      { item: "2nd week of July" },
      {
        item: `Merchants who meet the Verification requirements by the assessment date will automatically become
        Stellar Merchant.
        No application is required to qualify to be a Stellar Merchant.
        Merchants will be notified of their statuses at the end of each assessment period.`,
      },
    ],
  },
  {
    title: "How to become a Stellar Merchant.",
    description: `A Merchant can become a Stellar Merchant when their account is in good standing and have met the
    following criteria;`,
    subTitle: "",
    points: [
      { item: "90% response time" },
      { item: "4.5 overall rating" },
      { item: "At least 25 fulfilled requests in 6 months" },
      { item: "Met value allocated per category for each sale" },
    ],
  },
  {
    title: "",
    description: "",
    subTitle: "A merchant’s account is said to be in good standing when;",
    points: [
      {
        item: `Seller quotes price less than the lower range or within the range indicated by the buyer as
        often as possible.`,
      },
      {
        item: "Responds to at least one request once every month in 6 months.",
      },
      { item: "Has no disputes on their account." },
    ],
  },
  {
    title: "",
    description: "",
    subTitle: "",
    points: [
      {
        item: ``,
      },
    ],
  },
];

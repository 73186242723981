
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import AppRouting from './core/AppRouting';
// import 'aos/dist/aos.css'; 

function App() {
  return (
    <BrowserRouter>
    <AppRouting />
  </BrowserRouter>
  );
}

export default App;

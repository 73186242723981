import React from 'react'
import { Container } from 'react-bootstrap'
import { Banner } from './component/banner/BannerCard'
import { PostWithPamo } from './component/post-with-pamo/PostWithPamo'
import { Rewards } from './component/rewards/Rewards'
import './buy-on-pamo.scss'
import { BuyOnPamobanner } from './component/buyOnPamo/BuyOnPamobanner'
// import BuyOnPamo2 from './component/buyOnPamo/BuyOnPamo2'

export const BuyOnPamo = () => {
  return (
    <section className="section-main">
        {/* <Banner/> */}
        <BuyOnPamobanner/>
        <PostWithPamo/>
        <Rewards/>
    </section>
  )
}

import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import "./hover-cards.scss";
import { ArrowPointer } from "../../../common/navigating-arrow/ArrowPointer";

export const HoverCard = ({ data }: any) => {
  const [backgroundColor, setBackgroundColor] = useState("#F5F5F5");
  // const [maxCardHeight, setMaxCardHeight] = useState(0);

  const cardBodyStyle = {
    backgroundColor,
  };

  const handleMouseEnter = () => {
    if (data.background) {
      setBackgroundColor(data.background);
    }
  };

  const handleMouseLeave = () => {
    if (data.background) {
      setBackgroundColor("#F5F5F5");
    }
  };

  return (
    <div
      className="hover-card-wrapper rows-lg-3 rows-md-2 rows-sm-1"
      data-aos="fade-up"
      data-aos-easing="linear"
      data-aos-duration="500"
      data-aos-once="false"
    >
      <Card
        className="hover-card-parent  p-4 rounded-5 border-0 "
        style={cardBodyStyle}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Card.Body className="hover-card-text-area">
          <div className="hover-card-title fs-4 fw-bold mb-2">
            {data.title}
          </div>
          {Array.isArray(data.body) && data.body.length >= 2 ? (
            <div className="hover-card-body-list fw-light ">
              {data.body.map((bodyItem: any, index: number) => (
                <ul key={index}>
                  <li className="fs-5">{bodyItem?.list}</li>
                </ul>
              ))}
            </div>
          ) : (
            <div className="hover-card-body fw-light fs-5">
              {typeof data.body === "string" ? data.body : ""}
            </div>
          )}
        </Card.Body>
        <Card.Footer className="bg-transparent border-0">
          <div className="hover-card-id text-end fs-1 fw-bold">{data.id}</div>
        </Card.Footer>
      </Card>
      {/* <ArrowPointer/> */}
    </div>
  );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-container {
  padding: 0px 10%;
  width: 100vw; }

.website-logo {
  width: 80px; }

.menu {
  padding: 20px 10%;
  font-size: 17px; }

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: unset !important; }

.phone-icon {
  font-size: 30px; }

.talk-to-us-container {
  margin-left: auto; }

.dropdown-menu {
  border: unset !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px); }

@media only screen and (max-width: 1364px) {
  .menu {
    margin-left: -15% !important; } }

@media only screen and (max-width: 1176px) {
  .menu {
    margin-left: -35% !important; } }

@media only screen and (max-width: 992px) {
  .menu {
    margin-left: 0% !important; } }
`, "",{"version":3,"sources":["webpack://./src/presentation/main/common/navbar/navbar.scss"],"names":[],"mappings":"AAAA;EACE,gBAAe;EACf,YAAW,EAAA;;AAGb;EACE,WAAW,EAAA;;AAGb;EACA,iBAAgB;EAChB,eAAc,EAAA;;AAGd;EACE,qBAAqB;EACrB,UAAU;EACV,4BAA4B,EAAA;;AAE9B;EACE,eAAe,EAAA;;AAGjB;EACE,iBAAiB,EAAA;;AAGnB;EAGE,wBAAwB;EACxB,yCAAyC;EACzC,4BAA4B;EAC5B,oCAAoC,EAAA;;AAItC;EACE;IACE,4BAA4B,EAAA,EAC7B;;AAIH;EACA;IACE,4BAA4B,EAAA,EAC7B;;AAGD;EACE;IACE,0BAA0B,EAAA,EAC3B","sourcesContent":[".navbar-container{\n  padding:0px 10%;\n  width:100vw;\n}\n\n.website-logo {\n  width: 80px;\n}\n\n.menu{\npadding:20px 10%;\nfont-size:17px;\n}\n\n.navbar-toggler:focus {\n  text-decoration: none;\n  outline: 0;\n  box-shadow: unset !important;\n}\n.phone-icon {\n  font-size: 30px;\n}\n\n.talk-to-us-container {\n  margin-left: auto;\n}\n\n.dropdown-menu{\n  // background: rgba(255, 255, 255, 0.13);\n  // border-radius: 16px;\n  border: unset !important;\n  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);\n  backdrop-filter: blur(5.5px);\n  -webkit-backdrop-filter: blur(5.5px);\n}\n\n\n@media only screen and (max-width: 1364px) {\n  .menu{\n    margin-left: -15% !important;\n  }\n  }\n  \n\n@media only screen and (max-width: 1176px) {\n.menu{\n  margin-left: -35% !important;\n}\n}\n\n@media only screen and (max-width: 992px) {\n  .menu{\n    margin-left: 0% !important;\n  }\n  }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
